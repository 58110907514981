import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom';

import './setting.css'


const Settings = () => {
    const TOP_PAGE_PATH = '/';
    const loginPage = window.location.pathname === TOP_PAGE_PATH;
    const { i18n, t } = useTranslation(['general'])
    const navigate = useNavigate()
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng)
        setSelectedLanguage(lng)
        localStorage.setItem('selectedLanguage', lng)
    }

    const [selectedLanguage, setSelectedLanguage] = useState('') 
    const isInitialMount = useRef(true)

    useEffect(() => {
        const storedLanguage = localStorage.getItem('selectedLanguage')
        if (storedLanguage && isInitialMount.current) {
            setSelectedLanguage(storedLanguage)
            i18n.changeLanguage(storedLanguage)
        }
    }, []); 
    
    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false
        }
    }, [])
    

return (
    <div className='settingcontainer'>
        <div className='hamburger-menu'>
            <input type='checkbox' id='all-menu-btn-check' />
            <label htmlFor='all-menu-btn-check' className='menu-btn'><span></span></label>
            <div className='menu-content'>
                <div className='menu-wrap'>
                    <div className='menu-boxs'>
                        {!loginPage && (
                            <>
                                <div>
                                    <button onClick={() => { navigate('/top') }} className=''>Top</button>
                                </div>
                                <br></br>
                                <div>
                                <button onClick = {() => { window.location.href = '/tyre-sets' }} className=''>{t('hamburger-menu.TyreSetList')}</button>
                                </div>
                                <div>
                                    <button onClick = {() => { window.location.href = '/tyre-pressure-recommend' }} className=''>{t('hamburger-menu.RecommendedTyrePressure')}</button>
                                    <button onClick = {() => { window.location.href = '/tyre-air-pressure-new' }} className=''>{t('hamburger-menu.MeasureTyrePressuret')}</button>
                                </div>
                                <br></br>
                                <div>
                                    <button onClick = {() => { window.location.href = '/lap-charts/new' }} className=''>{t('hamburger-menu.CreateOuting')}</button>
                                    <button onClick = {() => { window.location.href = '/lap-charts' }} className=''>{t('hamburger-menu.OutingList')}</button>
                                    <button onClick = {() => { window.location.href = '/lap-charts/race' }} className=''>Lap chart</button>
                                </div>
                                <br></br>
                                <div>
                                    <button onClick = {() => { window.location.href = '/refuel-speed' }} className=''>{t('hamburger-menu.ManageRefuelingSpeed')}</button>
                                </div>
                            </>
                        )}
                    </div>
                    {!loginPage && (
                    <div className='lang-switch-box'>
                        <h3 className=''>
                            {t('switchLanguage')}
                        </h3>
                        <div className='button-box'>
                            <button onClick={() => changeLanguage('en')} className={`button ${selectedLanguage === 'en' ? 'action' : 'no-action'}`}>English</button>
                            <button onClick={() => changeLanguage('jp')} className={`button ${selectedLanguage === 'jp' ? 'action' : 'no-action'}`}>日本語</button>
                        </div>
                    </div>
                    )}
                </div> 
            </div>
        </div>
    </div>
)
}

export default Settings
