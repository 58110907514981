import React, { useEffect, useState } from 'react'

import { Bar } from 'react-chartjs-2'

import SelectBoxLabels from './SelectBoxLabels'

import { useTranslation } from 'react-i18next'

const CornerChart = (props) => {
    const { t } = useTranslation(['core'])

    const [ chartData, setChartData ] = useState({})
    const [ chartOptions, setChartOptions ] = useState({})
    const [ selectOptions, setSelectOptions ] = useState({})

    const selectedChart = props.selectedCornerChart
    const setSelectedChart = props.setSelectedCornerChart

    const Obj_chart_combination = props.Obj_chart_combination
    const Obj_circuit_sens = props.Obj_circuit_sens

    const Obj_KPI_base = props.Obj_KPI_base
    const Obj_KPI_recommend = props.Obj_KPI_recommend
    const Obj_KPI_selected = props.Obj_KPI_selected
    // const Obj_KPI_actual = props.Obj_KPI_actual

    const isSetup = props.isSetup
    const isCornerSensitivity = props.isCornerSensitivity

    useEffect(() => {
        if (!Obj_chart_combination) return

        const getSelectOptions = () => {
            const performanceKeys = Obj_chart_combination['performance']
            const performanceKeyNames = performanceKeys.map(pk => pk.name)

            if (!isSetup) {
                performanceKeyNames.unshift('Corner Sensitivity')
            }

            return performanceKeyNames
        }

        const options = getSelectOptions()
        setSelectOptions(options)

        if (isSetup && !isCornerSensitivity) {
            setSelectedChart(options[0])
        } else {
            if (!selectedChart) setSelectedChart('Corner Sensitivity')
        }

    }, [Obj_chart_combination])

    useEffect(() => {
        if (!selectedChart) return

        const { labels, datasets } = selectedChart === 'Corner Sensitivity' ? getCornerSensitivityData() : getPerformanceData(selectedChart)

        setChartData({
            labels: labels,
            datasets: datasets
        })

        const chartOptions = {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    position: 'right',
                    labels: {
                        color: 'white',
                    },
                },
            },
            scales: {
                x: {
                    ticks: {
                        color: 'white',
                    },
                },
                y: {
                    ticks: {
                        color: 'white',
                    },
                },
            }
        }

        if (isCornerSensitivity && isSetup) {
            chartOptions.indexAxis =  'y'
            chartOptions.plugins.legend.display = false
        }

        setChartOptions(chartOptions)
    }, [selectedChart, Obj_KPI_base, Obj_KPI_recommend])

    const getCornerSensitivityData = () => {
        if (Object.keys(Obj_circuit_sens).length === 0) return { labels: [], datasets: [] }
        const labels = Obj_circuit_sens.map(d => d['name'])
        const data = Obj_circuit_sens.map(d => d['value'])

        const datasets = [{
            data: data,
            label: '',
            backgroundColor: '#FFA800', // blue-ish
            borderColor: '#FFA800',
            barPercentage: 0.6,
            categoryPercentage: 0.7,
        }]

        return { labels, datasets }
    }

    const getPerformanceData = (selectedChart) => {
        const baseData = Obj_KPI_base[selectedChart]
        const recommendData = Obj_KPI_recommend[selectedChart]

        const labels = []
        const baseChartData = []
        const recommendChartData = []

        for (const corner in baseData) {
            labels.push(baseData[corner].name)
            baseChartData.push(baseData[corner].value)
        }
    
        for (const corner in recommendData) {
            recommendChartData.push(Math.abs(recommendData[corner].value - baseData[corner].value))
        }

        const datasets = [
        {
            data: recommendChartData,
            label: 'Recommend',
            backgroundColor: '#FFA800', // yellow
            borderColor: '#FFA800',
        }]

        if (Obj_KPI_selected) {
            const selectedData = Obj_KPI_selected[selectedChart]
            const selectedChartData = []
            for (const corner in selectedData) {
                selectedChartData.push(Math.abs(selectedData[corner].value - baseData[corner - 1].value))
            }

            datasets.push({
                data: selectedChartData,
                label: 'Selected',
                backgroundColor: '#33CCFF', // blue
                borderColor: '#03DBD0'
            })
        }

        return { labels, datasets }
    }

    return (
        chartData.labels && (
            <> 
                <div className='corner-chart-container'>
                    <div className='corner-chart-content'>
                        <Bar
                            data={ chartData }
                            options={chartOptions} />
                    </div>
                </div>
                { !isCornerSensitivity && 
                <div className='select-container'>
                    <SelectBoxLabels title={t('cornerChart.cornerRelations')} onChangeType='cornerChart' setState={ setSelectedChart } options={ selectOptions } value={ selectedChart } />
                </div>
                }
            </>
        )
    )
}

export default CornerChart