import React, { useEffect, useState } from 'react'

import { Bar } from 'react-chartjs-2'

import SelectBoxLabels from './SelectBoxLabels'

import { useTranslation } from 'react-i18next'

const CompareTimeChart = (props) => {
    const { t } = useTranslation(['core'])

    const [chartData, setChartData] = useState({})
    const [chartOptions, setChartOptions] = useState({})
    const [compareTimeLabels, setCompareTimeLabels] = useState([])

    const selectedCompareTime = props.selectedCompareTime
    const setSelectedCompareTime = props.setSelectedCompareTime
    const df_compareTime = props.df_compareTime

    useEffect(() => {
        if (!df_compareTime) return

        let tempCompareTimeLabels = df_compareTime.columns
        const idIndex = tempCompareTimeLabels.indexOf('id')
        if (idIndex > -1) tempCompareTimeLabels.splice(idIndex, 1)
        // 元々のカラム名がSector01Duration of Sector [s]のように長いため、ここで表示だけ変更している
        setCompareTimeLabels(tempCompareTimeLabels.map(label => label.split('Duration')[0]))
        if (!selectedCompareTime) setSelectedCompareTime('LapTime');
      }, [])

    useEffect(() => {
        if (!selectedCompareTime) return

        const labels = df_compareTime.index
        let selectedIndex

        if (df_compareTime.columns[0] === 'id') {
            selectedIndex = df_compareTime.columns.indexOf(selectedCompareTime)
        } else {
            selectedIndex = df_compareTime.columns.indexOf(selectedCompareTime) + 1
        }

        const compareTimeChartData = df_compareTime
            .iloc({ columns: [selectedIndex] })
            .values.flat()

        setChartData({
            labels: labels.slice(1),
            datasets: [
                {
                    data: compareTimeChartData.slice(1),
                    // data: compareTimeChartData,
                    label: selectedCompareTime,
                    backgroundColor: '#FFA800', // yellow
                    borderColor: '#FFA800'
                }
            ]
        })

        setChartOptions({
            indexAxis: 'y',
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    position: 'right',
                    labels: {
                        color: 'white',
                    },
                },
            }, 
            scales: {
                x: {
                    ticks: {
                        color: 'white',
                    },
                },
                y: {
                    ticks: {
                        color: 'white',
                    },
                },
            },
            // options: {
            //     scales: {
            //         yAxes: [
            //             {
            //                 ticks: {
            //                     beginAtZero: true,
            //                     fontColor: '#fff',
            //                     callback: function (value, index, values) {
            //                         return value + 's'
            //                     }
            //                 }
            //             }
            //         ]
            //     }
            // }
        })
    }, [selectedCompareTime])

    return (
        chartData.labels && (
        <>
            <div className='compare-time-chart-content'>
            <div className='chart-container'>
                <Bar data={chartData} options={chartOptions} />
            </div>
            </div>
            <div className='select-container'>
                <SelectBoxLabels title={t('general.yAxis')} onChangeType='compareTime' setState={ setSelectedCompareTime } options={ compareTimeLabels } value={ selectedCompareTime } />
            </div>
        </>
        )
    )
}

export default CompareTimeChart
