import { DataFrame } from 'danfojs'

// ObjectよりInputのDataframe作成
const makeInputKPI = (Obj_Setup, df_DOEcondition, vehicle) => {
    let Obj_output = {}
    let parameter_names = vehicle.calculateKpiParameters
    let parameter_ObjNames = []

    for(let i = 0; i < parameter_names.length; i++) {
        for (let j = 0; j < df_DOEcondition.index.length; j++){
            if(parameter_names[i].name === df_DOEcondition.iloc({'rows':[j], 'columns':[0]}).values[0][0]){
                parameter_ObjNames.push('x' + (j+1))
            }
        }
    }

    Obj_output['id'] = Obj_Setup[parameter_ObjNames[0]].data.map(function (value, index) {return index});
    
    Obj_output['Spring_Wheelrate_Fr'] = Obj_Setup[parameter_ObjNames[0]].data.map(function (value) {
        return value*vehicle.springRatioFR**2
    });
    Obj_output['Spring_Wheelrate_Rr'] = Obj_Setup[parameter_ObjNames[1]].data.map(function (value) {
        return value*vehicle.springRatioRR**2
    });
    Obj_output['ARB_Wheelrate_Fr'] = Obj_Setup[parameter_ObjNames[2]].data.map(function (value) {
        return value*vehicle.arbRatioFR**2
    });
    Obj_output['ARB_Wheelrate_Rr'] = Obj_Setup[parameter_ObjNames[3]].data.map(function (value) {
        return value*vehicle.arbRatioRR**2
    });
    Obj_output['Roll_Stiff_Fr'] = Obj_output['Spring_Wheelrate_Fr'].map(function (value, index) {
        return (value+Obj_output['ARB_Wheelrate_Fr'][index])*1000*vehicle.treadFR**2*3.14/180/2
    });
    Obj_output['Roll_Stiff_Rr'] = Obj_output['Spring_Wheelrate_Rr'].map(function (value, index) {
        return (value+Obj_output['ARB_Wheelrate_Rr'][index])*1000*vehicle.treadRR**2*3.14/180/2
    });
    Obj_output['Roll_Stiff_ARBratio_Fr'] = Obj_output['ARB_Wheelrate_Fr'].map(function (value, index) {
        return (value/Obj_output['Spring_Wheelrate_Fr'][index])*100
    });
    Obj_output['Roll_Stiff_ARBratio_Rr'] = Obj_output['ARB_Wheelrate_Rr'].map(function (value, index) {
        return (value/Obj_output['Spring_Wheelrate_Rr'][index])*100
    });
    Obj_output['Roll_Stiff_Total'] = Obj_output['Roll_Stiff_Fr'].map(function (value, index) {
        return (value+Obj_output['Roll_Stiff_Rr'][index])
    });
    Obj_output['Roll_Stiff_Balance'] = Obj_output['Roll_Stiff_Fr'].map(function (value, index) {
        return (value/Obj_output['Roll_Stiff_Total'][index])*100
    });
    Obj_output['Heave_Total'] = Obj_output['Spring_Wheelrate_Fr'].map(function (value, index) {
        return (value + Obj_output['Spring_Wheelrate_Rr'][index])
    });
    Obj_output['Heave_Ratio'] = Obj_output['Spring_Wheelrate_Fr'].map(function (value, index) {
        return value / Obj_output['Spring_Wheelrate_Rr'][index]
    });
    Obj_output['Sprung_Resonance_Fr'] = Obj_output['Spring_Wheelrate_Fr'].map(function (value) {
        let Sprung_mass = vehicle.weight*vehicle.weightBalance/100/2-vehicle.unsprungMassFR
        return Math.sqrt(value*1000/Sprung_mass)/(2*3.14)
    });
    Obj_output['Sprung_Resonance_Rr'] = Obj_output['Spring_Wheelrate_Rr'].map(function (value) {
        let Sprung_mass = vehicle.weight*(100-vehicle.weightBalance)/100/2-vehicle.unsprungMassRR
        return Math.sqrt(value*1000/Sprung_mass)/(2*3.14)
    });
    Obj_output['Sprung_Resonance_Ratio'] = Obj_output['Sprung_Resonance_Fr'].map(function (value, index) {
        return Obj_output['Sprung_Resonance_Rr'][index]/value
    });

    for(let i = 0; i < Object.keys(Obj_output).length; i++){
        Obj_output[Object.keys(Obj_output)[i]] =  Array.prototype.slice.call(Obj_output[Object.keys(Obj_output)[i]])
    }
    let df = new DataFrame(Obj_output)
    return df
}

export default makeInputKPI