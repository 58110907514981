import * as dfd from 'danfojs'

import transRange from './transRange'
import makeBaseONNXobject from './makeBaseONNXobject'
import makeScatONNXobject from './makeScatONNXobject'
import makeInputdf from './makeInputdf'
import makeInputKPI from './makeInputKPI'
import groupByModel from './groupByModel'
import updateProgress from './updateProgress'

const optimizeArene = async (modelId, df_DOEcondition, Obj_circuit_map, Obj_chart_combination, df_baseSetup, DOEnum, Obj_parameter_combination, vehicleData, onnxModels, setupOptions) => {
    console.log('optimize Arene')
    // console.log('全引数', modelId, df_DOEcondition, Obj_circuit_map, Obj_chart_combination, df_baseSetup, DOEnum, Obj_parameter_combination, vehicleData)

    let scat_chart_list = []

    let df_scatTime
    let Obj_scat_result = {}
    let Obj_KPI_base_result = {}
    let Obj_KPI_recommend_result = {}

    const scatChartListResult = await setScatChartList(Obj_chart_combination);
    ({ scat_chart_list, df_scatTime, Obj_scat_result, Obj_KPI_base_result, Obj_KPI_recommend_result } = scatChartListResult)

    const KPI_list = Object.keys(Obj_KPI_base_result)

    // onnx
    const resultFromBaseResult = await baseResult(modelId, df_DOEcondition, onnxModels, df_baseSetup, scat_chart_list, Obj_chart_combination, KPI_list, Obj_KPI_base_result)
    const { Obj_baseSetup, df_baseInput, Obj_base_result, df_baseTime } = resultFromBaseResult
    Obj_KPI_base_result = resultFromBaseResult.Obj_KPI_base_result

    const resultFromScatResult = await scatResult(modelId, df_DOEcondition, df_baseSetup, DOEnum, Obj_parameter_combination, scat_chart_list, onnxModels, Obj_chart_combination, Obj_scat_result, df_scatTime, KPI_list, Obj_KPI_recommend_result)
    const { Obj_scatSetup, df_scatInput } = resultFromScatResult
    Obj_scat_result = resultFromScatResult.Obj_scat_result
    df_scatTime = resultFromScatResult.df_scatTime
    Obj_KPI_recommend_result = resultFromScatResult.Obj_KPI_recommend_result

    const df_baseKPI = makeInputKPI(Obj_baseSetup, df_DOEcondition, vehicleData)
    const df_scatKPI = makeInputKPI(Obj_scatSetup, df_DOEcondition, vehicleData)

    return {
        Obj_baseSetup, Obj_scatSetup,
        df_baseInput, df_scatInput,
        Obj_base_result, Obj_scat_result,
        Obj_KPI_base_result, Obj_KPI_recommend_result,
        df_baseTime, df_scatTime,
        df_baseKPI, df_scatKPI,
        scat_chart_list, onnxModels
    }
}

export default optimizeArene

// Obj_chart_combination を元に、各種変数の初期値を作る
async function setScatChartList(Obj_chart_combination) {
    return new Promise((resolve, reject) => {
        let size = 250
        const arrayScat = { 'id': transRange(size) }
        const df_scatTime = new dfd.DataFrame(arrayScat)
        const Obj_scat_result = {}
        const scat_chart_list = []
        const Obj_KPI_base_result = {}
        const Obj_KPI_recommend_result = {}

        for (const [key, value] of Object.entries(Obj_chart_combination)) {
            for (let i = 0; i < value.length; i++) {
                const chartName = value[i].name

                if (key === 'performance') {
                    Obj_KPI_base_result[chartName] = {}
                    Obj_KPI_recommend_result[chartName] = {}
                } else {
                    scat_chart_list.push(chartName)
                    Obj_scat_result[chartName] = new dfd.DataFrame(arrayScat)
                }
            }
        }

        resolve({ scat_chart_list, df_scatTime, Obj_scat_result, Obj_KPI_base_result, Obj_KPI_recommend_result })
    })
}

async function baseResult(modelId, df_DOEcondition, onnxModels, df_baseSetup, scat_chart_list, Obj_chart_combination, KPI_list, Obj_KPI_base_result) {
    console.log("start base")
    const Obj_baseSetup = makeBaseONNXobject(df_DOEcondition, df_baseSetup)
    console.log("Obj_baseSetup", Obj_baseSetup)
    const df_baseInput = makeInputdf(Obj_baseSetup, df_DOEcondition)
    console.log("df_baseInput", df_baseInput)

    // columns は doe condition の順番
    const xLength = df_baseInput.columns.length

    let Obj_base_result = {}

    let labelBase = df_baseSetup.iloc({ 'columns': ['1:'] }).columns
    let arrayBase = { 'id': transRange(labelBase.length) }
    let df_baseTime = new dfd.DataFrame(arrayBase)

    scat_chart_list.forEach(chart => {
        Obj_base_result[chart] = new dfd.DataFrame(arrayBase)
    })

    const baseInputs = []

    for (let i = 0; i < xLength; i++) {
        const baseInput = {
            name: `x${i + 1}`,
            shape: [labelBase.length, 1],
            datatype: df_baseInput.dtypes[i] === 'string' ? 'BYTES' : 'FP32',
            data: []
        }

        for (let j = 0; j < labelBase.length; j++) {
            baseInput.data.push([df_baseInput.iloc({ 'rows': [j], 'columns': [i] }).values[0][0]])
        }

        baseInputs.push(baseInput)
    }

    console.log('baseInputs', baseInputs)
    // /core/api/optimizeBase/ にリクエスト
    const reponseJson = await getResult({ inputs: baseInputs }, modelId)
    console.log('reponseJson', reponseJson)
    const processedSingleResult = await processSingleResult(reponseJson.data, onnxModels, scat_chart_list, Obj_chart_combination, Obj_base_result, df_baseTime, KPI_list, Obj_KPI_base_result)
    Obj_base_result = processedSingleResult.Obj_result
    df_baseTime = processedSingleResult.df_time

    return {
        Obj_baseSetup, // onnxModel のための input ?
        df_baseInput, // Obj_baseSetup と 作成した dataframe
        Obj_base_result, // getResult によって得られた計算結果
        df_baseTime, // 時間
        Obj_KPI_base_result // 引数をそのまま返す
    }
}

function getResult(inputs, modelId) {
    return new Promise((resolve, reject) => {
        console.log("start get result")

        const POST_OPTIONS = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                inputs: inputs,
                modelId,
            })
        }

        fetch(`/core/api/optimizeBase/`, POST_OPTIONS)
            .then(response => response.json())
            .then(responseJson => {
                resolve(responseJson)
            })
            .catch(error => {
                console.log("getResult error", error)
                reject()
            })
    })
}

function scatResult(modelId, df_DOEcondition, df_baseSetup, DOEnum, Obj_parameter_combination, scat_chart_list, onnxModels, Obj_chart_combination, Obj_scat_result, df_scatTime, KPI_list, Obj_KPI_recommend_result) {
    return new Promise(async (resolve, reject) => {
        console.log('===== Start Scat =====')
        const Obj_scatSetup = makeScatONNXobject(df_DOEcondition, df_baseSetup, DOEnum, Obj_parameter_combination)
        const df_scatInput = makeInputdf(Obj_scatSetup, df_DOEcondition)

        const xLength = df_scatInput.columns.length
        const caseLength = df_scatInput.index.length

        const SIZE = 250
        let lastIndex = 0

        let counter = 0

        const getResultPromises = []
        while (lastIndex < caseLength) {
            const scatInputs = []
            for (let i = 0; i < xLength; i++) {
                const scatInput = {
                    name: `x${i + 1}`,
                    shape: [SIZE, 1],
                    datatype: df_scatInput.dtypes[i] === 'string' ? 'BYTES' : 'FP32',
                    data: []
                }

                for (let j = 0; j < SIZE; j++) {
                    scatInput.data.push([df_scatInput.iloc({ 'rows': [lastIndex + j], 'columns': [i] }).values[0][0]])
                }

                scatInputs.push(scatInput)
            }
            // df_scatInput.index.length / SIZE の数だけ /core/api/optimizeBase/ にリクエスト
            getResultPromises.push(getResult({ inputs: scatInputs }, modelId))
            lastIndex += SIZE
            counter++
        }

        let responseCount = 0
        Promise.all(getResultPromises.map((promise) => {
            return promise.then(responseJson => {
                console.log('responseJson', responseCount, responseJson);
                responseCount++;
                updateProgress(((responseCount + 1) / getResultPromises.length) * 100);
                return processResult(responseJson, responseCount);
            });
        }))
            .then(() => {
                resolve({ Obj_scatSetup, df_scatInput, Obj_scat_result, df_scatTime, Obj_KPI_recommend_result });
            })
            .catch(error => {
                console.error('promise all error', error);
                reject(error);
            });

    })

    function processResult(responseJson, counter) {
        return new Promise(async (resolve, reject) => {
            if (counter === 1) {
                await processSingleResult(responseJson.data, onnxModels, scat_chart_list, Obj_chart_combination, Obj_scat_result, df_scatTime, KPI_list, Obj_KPI_recommend_result)
            } else {
                const outputs = responseJson.data.outputs[0]
                const data = outputs.data
                const shape = outputs.shape[0]

                const lastIndex = (counter - 1) * shape

                const onnxObjectResult = groupByModel(shape, data, onnxModels)

                scat_chart_list.forEach((chart, index) => {
                    const subArray = []
                    const timeSubArray = []

                    onnxModels.forEach((model, i) => {
                        if (model.includes(chart)) {
                            subArray.push(onnxObjectResult[i].value)
                        }

                        // Q: scat_chart_list[0] って具体的に何を指している?
                        // A: ファイルを見たところ Handling Mean を指しているらしい
                        if (index === 0) {
                            // なぜ LapTime も含めているのか？
                            if (model.includes('Sector') || model.includes('LapTime') || model.includes('v Max [kph]')) {
                                timeSubArray.push(onnxObjectResult[i].value)
                            }

                            KPI_list.forEach(kpi => {
                                if (model.includes(kpi)) {
                                    Obj_KPI_recommend_result[kpi][model].push(...onnxObjectResult[i].value)
                                }
                            })
                        }
                    })

                    const [indecesToAdd, dataToAdd] = transformData(subArray, lastIndex)
                    // 並列処理をしているから、append する位置を指定している
                    Obj_scat_result[chart] = Obj_scat_result[chart].append(dataToAdd, indecesToAdd)

                    // 実質的に一回だけの処理
                    if (timeSubArray.length) {
                        const [indecesToAddTime, dataToAddTime] = transformData(timeSubArray, lastIndex, true)
                        df_scatTime = df_scatTime.append(dataToAddTime, indecesToAddTime)
                    }
                })
            }
            resolve()
        })

        function transformData(dataToTransform, lastIndex, isTime) {
            const indecesToAdd = []
            // dataToTransform は２次元配列？
            // index は列番号に相当する
            const transformedData = dataToTransform[0].map((_, index) => {
                indecesToAdd.push(lastIndex + index)
                let temp = [
                    lastIndex + index,
                    ...dataToTransform.map(row => row[index]) // ２次元配列の業の要素を一つ一つ取得して展開
                ]

                if (isTime) {
                    temp.splice(1, 1) // なぜ 1 つめの要素を削除？ もしかしたら vMax ?
                    // さらになぜ slice(1) している？  結果的に 2 つめの要素から sum を計算している
                    const sum = temp.slice(1).reduce((acc, val) => acc + val, 0)
                    temp.push(sum)
                }

                return temp
            })

            return [indecesToAdd, transformedData]
        }
    }
}

function processSingleResult(responseJson, onnxModels, scat_chart_list, Obj_chart_combination, Obj_result, df_time, KPI_list, Obj_KPI_result) {
    return new Promise(async (resolve, reject) => {
        const outputs = responseJson.outputs[0]
        const data = outputs.data
        const shape = outputs.shape[0]

        const objChartBalanceName = Obj_chart_combination['Balance'][2].name

        const onnxObjectResult = groupByModel(shape, data, onnxModels)

        onnxModels.forEach((model, i) => {
            scat_chart_list.forEach(chart => {
                if (model.includes(chart)) {
                    if (model.includes(objChartBalanceName)) {
                        const tempValue = onnxObjectResult[i].value.map(m => (100 - m))
                        onnxObjectResult[i].value = tempValue
                    }

                    Obj_result[chart].addColumn(model, onnxObjectResult[i].value, { inplace: true })
                }
            })

            if (model.includes('Sector')) {
                df_time.addColumn(model, onnxObjectResult[i].value, { inplace: true })
            }

            KPI_list.forEach(kpi => {
                if (model.includes(kpi)) {
                    if (!Obj_KPI_result[kpi]) {
                        Obj_KPI_result[kpi] = {}
                    }

                    Obj_KPI_result[kpi][model] = onnxObjectResult[i].value
                }
            })
        })

        df_time.addColumn('LapTime', df_time.iloc({ columns: ["1:"] }).sum().values, { inplace: true })

        const vMaxModelName = 'v Max [kph]'
        const vMaxFound = onnxObjectResult.find(m => m.name === vMaxModelName)
        if (vMaxFound) {
            const vMaxValue = vMaxFound.value
            df_time.addColumn(vMaxModelName, vMaxValue, { inplace: true })
        }

        resolve({ Obj_result, df_time, Obj_KPI_result })
    })
}