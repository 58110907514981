// //Tableデータ作成
import { baseColors, recommendColors } from '../services/plotColors'

const SetupTable = (df, type, Obj_parameter_combination) => {
    const rows = []

    const KPIrows = ['Roll_Stiff_Total', 'Roll_Stiff_Balance', 'Heave_Total', 'Heave_Ratio', 'Sprung_Resonance_Ratio']

    let baseCount = 0
    let recommendCount = 0

    for (let i = 0; i < df.index.length + 1; i++) {
        const cells = []


        for (let j = 0; j < df.columns.length + 1; j++) {
            if (type === 'kpi' && j !== 0 && !KPIrows.includes(df.columns[j])) continue

            if (i === 0) {
                cells.push(createHeaderCell(df, j, type))
            } else {
                let text = j === 0 ? df.index[i - 1] : df.iloc({ rows: [i - 1], columns: [j - 1] }).values[0]
                let headerColor = ''
                if (text.includes('Recommend') && text !== 'Recommend') {
                    headerColor = `th-header-${recommendColors[recommendCount % recommendColors.length].slice(1)}`
                    recommendCount++
                } else if (text.includes('Base')) {
                    headerColor = `th-header-${baseColors[baseCount % baseColors.length].slice(1)}`
                    baseCount++
                }

                cells.push(createDataCell(df, i, j, type, Obj_parameter_combination, headerColor))
            }
        }

        rows.push(<tr key={i}>{cells}</tr>)
    }

    return (
        <table>
            <thead>{rows[0]}</thead>
            <tbody>{rows.slice(1)}</tbody>
        </table>
    )
}

function createHeaderCell(df, columnIndex, type) {
    const columnName = type === 'kpi' ? df.columns[columnIndex] : df.columns[columnIndex - 1]
    let text = columnIndex === 0 ? 'parameter' : columnName

    if (type === 'laptime') {
        // 元々のカラム名がSector01Duration of Sector [s]のように長いため、ここで表示だけ変更している
        text = text.split('Duration')[0]
    }

    return (
        <td
            key={columnIndex}
            className={`table-header ${columnIndex < 3 ? `td-fixed-column td-fixed-column-${columnIndex}` : ''}`}
        >
            {text}
        </td>
    )
}

function createDataCell(df, rowIndex, columnIndex, type, Obj_parameter_combination, headerColor) {
    let text = columnIndex === 0 ? df.index[rowIndex - 1] : df.iloc({ rows: [rowIndex - 1], columns: [columnIndex - 1] }).values[0]
    const classList = ['td-content']

    const isRecommendHeader = text === 'Recommend'

    if (!isNaN(text)) {
        text = formatCellText(text, columnIndex, df, rowIndex, type, Obj_parameter_combination)
    } else if (type === 'laptime') {
        // 元々のカラム名がSector01Duration of Sector [s]のように長いため、ここで表示だけ変更している
        text = text.split('Duration')[0]
    }

    if (columnIndex < 3) {
        classList.push('td-fixed-column', `td-fixed-column-${columnIndex}`)
    }

    return (
        <td
            key={columnIndex}
            className={`
                ${classList.join(' ')} ${columnIndex < 3 ? `td-fixed-column td-fixed-column-${columnIndex}` : ''}
                ${isRecommendHeader ? 'th-recommend-header' : ''}
                ${headerColor}
            `}
        >
            {text}
        </td>
    )
}

function formatCellText(text, columnIndex, df, rowIndex, type, Obj_parameter_combination) {
    const decimal = type === 'laptime' ? 3 : 2
    text = parseFloat(text).toFixed(decimal)
    const recommended = parseFloat(text)
    let highlightClass = ''

    if (columnIndex === df.columns.length) {
        let base = df.iloc({ rows: [rowIndex - 1], columns: [0] }).values[0][0]
        const parameterName = df.index[rowIndex - 1]
        const parameterCombination = Object.keys(Obj_parameter_combination).find(key =>
            key.includes(parameterName)
        )

        if (parameterCombination) {
            const value = Obj_parameter_combination[parameterCombination].find(p => p.name === base)
            if (value) base = parseFloat(value.value).toFixed(decimal)
        }

        if (recommended > base) {
            highlightClass = type === 'laptime' ? 'td-up-green' : 'td-up-red'
        } else if (recommended < base) {
            highlightClass = type === 'laptime' ? 'td-down-red' : 'td-down-green'
        }
    }

    return highlightClass ? <span className={highlightClass}>{recommended}</span> : recommended
}

export default SetupTable