import React, { useEffect, useState } from 'react';

import * as dfd from 'danfojs';

import ScatterPlot from './ScatterPlot'
import PlotlyGraph from './PlotlyGraph'
import SelectBoxLabels from './SelectBoxLabels'

import extractContinuous from '../services/extractContinuous';
import ResultMaximize from './ResultMaximize';

import { useTranslation } from 'react-i18next';

const ResultTrends = (props) => {
    const { t } = useTranslation(['core']);

    const [ scatLabels, setScatLabels ] = useState([])
    const [ scatGroupLabels, setScatGroupLabels ] = useState([])
    const [ rollStiffLabels, setRollStiffLabels ] = useState([])
    const [ heaveLabels, setHeaveLabels ] = useState([])

    const [ selectedGraphGroup, setSelectedGraphGroup ] = useState('')
    const [ selectedX, setSelectedX ] = useState('')
    const [ selectedY, setSelectedY ] = useState('LapTime')
    const [ selectedColorChart, setSelectedColorChart ] = useState('LapTime')

    const [ selectedRollStiffX, setSelectedRollStiffX ] = useState('Roll_Stiff_Balance')
    const [ selectedRollStiffY, setSelectedRollStiffY ] = useState('Roll_Stiff_Total')
    const [ selectedHeaveX, setSelectedHeaveX ] = useState('Heave_Ratio')
    const [ selectedHeaveY, setSelectedHeaveY ] = useState('Heave_Total')

    const [scat_chart_1, setScat_chart_1] = useState(props.scat_chart_1)
    const [scat_chart_2, setScat_chart_2] = useState(props.scat_chart_2)
    const [scat_chart_3, setScat_chart_3] = useState(props.scat_chart_3)

    const [df_baseHandling, setDf_baseHandling] = useState(props.df_baseHandling)
    const [df_baseAero, setDf_baseAero] = useState(props.df_baseAero)
    const [df_baseMech, setDf_baseMech] = useState(props.df_baseMech)

    const [ df_scatHandling, setDf_scatHandling ] = useState(props.df_scatHandling)
    const [ df_scatAero, setDf_scatAero ] = useState(props.df_scatAero)
    const [ df_scatMech, setDf_scatMech ] = useState(props.df_scatMech)

    const [ maximizeOpen, setMaximizeOpen ] = useState(false)
    const [ maximizeContent, setMaximizeContent ] = useState()
    const [ maximizeTitle, setMaximizeTitle ] = useState()

    const [dispaly_scatHeave, sedtispaly_scatHeave] = useState(true);
    const [dispaly_secgrah, setdispaly_secgrah] = useState(true);
    const [dispaly_thirdgrah, setdispaly_thirdgrah] = useState(true);
    
    // const [ df_scatRollStiff, setDf_scatRollStiff ] = useState(props.df_scatKPI)
    // const [ df_scatHeave, setDf_scatHeave ] = useState(props.df_scatKPI)

    const df_baseSetup = props.df_baseSetup
    const df_mapDriver = props.df_mapDriver

    const df_baseInput = props.df_baseInput
    const df_baseTime = props.df_baseTime
    const Obj_base_result = props.Obj_base_result

    const df_scatRollStiff = props.df_scatKPI
    const df_scatHeave = props.df_scatKPI

    const df_scatInput = props.df_scatInput
    const df_scatTime = props.df_scatTime
    const Obj_scat_result = props.Obj_scat_result

    const listAns = props.listAns
    const df_DOEcondition = props.df_DOEcondition

    const Obj_chart_combination = props.Obj_chart_combination

    const df_baseKPI = props.df_baseKPI
    const df_scatKPI = props.df_scatKPI

    useEffect(() => {
        const getScatLabels = () => {
            if (!df_baseSetup) return []

            let plotLabels = Array.from(extractContinuous(df_baseSetup))
            let KPIlabels = df_baseKPI.columns

            const categoriesToAdd = []
            if (df_DOEcondition) {
                const categoryIndex = df_DOEcondition.columns.indexOf('Category_name1')
                df_DOEcondition.values.forEach((d) => {
                    if (d[categoryIndex] !== '-') {
                        categoriesToAdd.push(d[0])
                    }
                })
            }

            const arraySectors = ['LapTime']

            const labels = [...plotLabels, ...categoriesToAdd, ...KPIlabels, ...arraySectors, ...df_mapDriver.index]
            setSelectedX(labels[0])
            return labels
        }

        const getScatGroupLabels = () => {
            if (!Obj_chart_combination) return []

            const labels = Object.keys(Obj_chart_combination)
            labels.pop()

            setSelectedGraphGroup(labels[0])
            return labels
        }

        const getKPILabels = (type) => {
            if (!df_scatKPI) return []

            let labels = df_scatKPI.columns
            if (type === 'rollStiff') {
                labels = labels.filter((l) =>
                    l.includes('Roll_Stiff') ||
                    l.includes('Wheelrate') ||
                    l.includes('LapTime')
                )
            } else {
                labels = labels.filter((l) =>
                    l.includes('Heave') ||
                    l.includes('Spring_Wheelrate') ||
                    l.includes('Sprung_Resonance') ||
                    l.includes('LapTime')
                )
            }

            return labels
        }

        if (df_mapDriver) {
            const handlingIndex = df_mapDriver.columns.indexOf('Handling')
            const firstNotEmpty = df_mapDriver.values.findIndex(v => v[handlingIndex] !== '-')
            const parameterName = df_mapDriver.index[firstNotEmpty]
    
            if (firstNotEmpty > -1) {
                setSelectedY(parameterName)
            }
        }

        setScatLabels(getScatLabels())
        setScatGroupLabels(getScatGroupLabels())
        setRollStiffLabels(getKPILabels('rollStiff'))
        setHeaveLabels(getKPILabels('heave'))
    }, [props])

    useEffect(() => {
        if (Object.keys(Obj_chart_combination).length === 0 || !selectedGraphGroup) return

        const graphgroup = selectedGraphGroup

        const tempScat_chart_1 = Obj_chart_combination[graphgroup][0].name
        const tempScat_chart_2 = Obj_chart_combination[graphgroup][1].name
        const tempScat_chart_3 = Obj_chart_combination[graphgroup][2].name

        setScat_chart_1(tempScat_chart_1)
        setScat_chart_2(tempScat_chart_2)
        setScat_chart_3(tempScat_chart_3)

        setDf_baseHandling(
            dfd.concat({
                dfList: [df_baseInput, df_baseTime, Obj_base_result[tempScat_chart_1]],
                axis: 1,
            })
        )
        setDf_baseAero(
            dfd.concat({
                dfList: [df_baseInput, df_baseTime, Obj_base_result[tempScat_chart_2]],
                axis: 1,
            })
        )
        setDf_baseMech(
            dfd.concat({
                dfList: [df_baseInput, df_baseTime, Obj_base_result[tempScat_chart_3]],
                axis: 1,
            })
        )

        setDf_scatHandling(
            dfd.concat({
                dfList: [df_scatInput, df_scatTime, Obj_scat_result[tempScat_chart_1]],
                axis: 1,
            })
        )

        setDf_scatAero(
            dfd.concat({
                dfList: [df_scatInput, df_scatTime, Obj_scat_result[tempScat_chart_2]],
                axis: 1,
            })
        )
        setDf_scatMech(
            dfd.concat({
                dfList: [df_scatInput, df_scatTime, Obj_scat_result[tempScat_chart_3]],
                axis: 1,
            })
        )
    }, [selectedGraphGroup])

    // const displayhandscatHeave = () => {
    //     sedtispaly_scatHeave(!dispaly_scatHeave)
    // }

    // const displayhandsecgrah = () => {
    //     setdispaly_secgrah(!dispaly_secgrah)
    // }

    // const displayhandthirdgrah = () => {
    //     setdispaly_thirdgrah(!dispaly_thirdgrah)
    // }

    useEffect(() => {
      if (!maximizeOpen) return;

      const contentMap = {
          'Roll Stiffness': ResultRollStiffness(),
          'Heave': ResultHeave(),
          'Scatter Chart': ResultScatChart(),
          'Multi dimension chart': ResultMultiChart(),
      };

      const contentComponent = contentMap[maximizeTitle];
      if (contentComponent) {
          setMaximizeContent(contentComponent);
      };
    }, [selectedRollStiffX, selectedRollStiffY, selectedHeaveX, selectedHeaveY, selectedGraphGroup, selectedX, selectedY]);
    
    const displayResizeHandler = (e) => {
        const type = e.currentTarget.dataset.type;
        setMaximizeOpen(true);
        const resultPlace = e.currentTarget.closest('.resize-container');
    
        if (!resultPlace) return;
    
        if (type === 'rollstiffness') {
          setMaximizeTitle('Roll Stiffness');
          setMaximizeContent(ResultRollStiffness());
        } else if (type === 'Heave') {
          setMaximizeTitle('Heave');
          setMaximizeContent(ResultHeave());
        } else if (type === 'scatChart') {
          setMaximizeTitle('Scatter Chart');
          setMaximizeContent(ResultScatChart());
        } else if (type === 'dimensionchart') {
          setMaximizeTitle('Multi dimension chart');
          setMaximizeContent(ResultMultiChart());
        }
    
        const scaleValue = resultPlace.dataset.scaleValue ? parseFloat(resultPlace.dataset.scaleValue) : 1;
    
        if (scaleValue === 3) {
            resultPlace.style.height = '32%';
            resultPlace.dataset.scaleValue = null;
        } else {
            resultPlace.style.height = '150%';
            resultPlace.dataset.scaleValue = 3;
        }
    }

    const ResultRollStiffness = () => {
      return (
        <div className={`canvas-container ${dispaly_scatHeave  ? 'scat-container' : 'display-none'}`}>
          <ScatterPlot
            list={ listAns }
            df_scatter={ df_scatRollStiff }
            df_base={ df_baseKPI }
            selectedX={ selectedRollStiffX }
            selectedY={ selectedRollStiffY }
            selectedColorChart={ selectedColorChart }
          />
          <div className={`${dispaly_scatHeave  ? 'select-container' : 'display-none'}`}>
            <SelectBoxLabels title='X軸' onChangeType='rollStiff' setState={setSelectedRollStiffX} options={rollStiffLabels} value={selectedRollStiffX}/>
            <SelectBoxLabels title='Y軸' onChangeType='rollStiff' setState={setSelectedRollStiffY} options={rollStiffLabels} value={selectedRollStiffY}/>
          </div>
        </div>
      )
    }

    const ResultHeave = () => {
      return (
        <div className={`canvas-container ${dispaly_scatHeave  ? 'scat-container' : 'display-none'}`}>
          <ScatterPlot
              list={ listAns }
              df_scatter={ df_scatHeave }
              df_base={ df_baseKPI }
              selectedX={ selectedHeaveX }
              selectedY={ selectedHeaveY }
              selectedColorChart={ selectedColorChart }
          />
          <div className={`${dispaly_scatHeave  ? 'select-container' : 'display-none'}`}>
            <SelectBoxLabels
              title='X軸' onChangeType='heave' setState={setSelectedHeaveX} options={heaveLabels} value={selectedHeaveX}/>
            <SelectBoxLabels title='Y軸' onChangeType='heave' setState={setSelectedHeaveY} options={heaveLabels} value={selectedHeaveY}/>
          </div>
      </div>
      )
    }

    const ResultScatChart = () => {
      return (
        <div className='graph-container-ver'>
          <div className='graph-group'>
            { df_scatHandling && (
                <div className='graph'>
                    <div className={`canvas-container ${dispaly_secgrah  ? 'select-container' : 'display-none'}`}>
                        <ScatterPlot
                            id={ scat_chart_1 }
                            list={ listAns }
                            df_scatter={ df_scatHandling }
                            df_base={ df_baseHandling }
                            selectedX={ selectedX }
                            selectedY={ selectedY }
                            selectedColorChart={ selectedColorChart }
                        />
                    </div>
                </div>
            )}

            { df_scatAero && (
                <div className='graph'>
                    <div className={`canvas-container ${dispaly_secgrah  ? 'select-container' : 'display-none'}`}>
                        <ScatterPlot
                            id={ scat_chart_2 }
                            list={ listAns }
                            df_scatter={ df_scatAero }
                            df_base={ df_baseAero }
                            selectedX={ selectedX }
                            selectedY={ selectedY }
                            selectedColorChart={ selectedColorChart }
                        />
                    </div>
                </div>
            )}

            { df_scatMech && (
                <div className='graph'>
                  {/* <div className='title-flex'>
                      <div className='graph-title'>{scat_chart_3}</div>
                      <button className={`${dispaly_secgrah ? 'display-on' : 'hidden-on'}`} onClick={ displayhandsecgrah }>{` ${dispaly_scatHeave ? 'すべて非表示' : 'すべて表示'}`}</button>
                    </div> */}
                    <div className={`canvas-container ${dispaly_secgrah  ? 'select-container' : 'display-none'}`}>
                        <ScatterPlot
                            id={ scat_chart_3 }
                            list={ listAns }
                            df_scatter={ df_scatMech }
                            df_base={ df_baseMech }
                            selectedX={ selectedX }
                            selectedY={ selectedY }
                            selectedColorChart={ selectedColorChart }
                        />
                    </div>
                </div>
            )}
            </div>
            <div className='display-flex'>
                { df_scatHandling && (
                    <div className={`${dispaly_secgrah  ? 'select-container' : 'display-none'}`}>
                        <SelectBoxLabels title='散布図' onChangeType='scatGroup' setState={ setSelectedGraphGroup } options={ scatGroupLabels } value={ selectedGraphGroup } />
                        <SelectBoxLabels title='X軸' onChangeType='scatterPlot' setState={ setSelectedX } options={ scatLabels } value={ selectedX } />
                        <SelectBoxLabels title='Y軸' onChangeType='scatterPlot' setState={ setSelectedY } options={ scatLabels } value={ selectedY } />
                    </div>
                )}
            
          </div>
        </div>
      )
    }

    const ResultMultiChart = () => {
      return (
        <div className={`plotly-container ${dispaly_thirdgrah  ? '' : 'display-none'}`}>
          <PlotlyGraph
              df_scatHandling={df_scatHandling}
              df_scatAero={df_scatAero}
              df_scatMech={df_scatMech}
              selectedX={selectedX}
              selectedY={selectedY}
              selectedColorChart={selectedColorChart}
              style={{ width: '10px', height: '60px' }}
          />
        </div>
      )
    }

    return (
        <>
            <div className='result-containers'>
                <div className='select-color'>
                    <div className='select-container'>
                        <SelectBoxLabels title={t('resultTrend.colorChart')} onChangeType='scatterPlot' setState={ setSelectedColorChart } options={ scatLabels } value={ selectedColorChart } />
                    </div>
                </div>
                { df_scatKPI && (
                      <div className= 'kpi-container resize-container'>
                        { df_scatRollStiff && (
                            <div className='graph w-50 result-graph-container'>
                                <div className='graph-title'><p>Roll Stiffness</p>
                                  <button data-type='rollstiffness' onClick={ displayResizeHandler }>
                                    <div className='table-maximize'></div>
                                  </button>
                                </div>
                                
                                <ResultRollStiffness />
                            </div>
                        )}

                        { df_scatHeave && (
                            <div className='graph w-50 result-graph-container'>
                                  <div className='graph-title'><p>Heave</p>
                                  <button data-type='Heave' onClick={ displayResizeHandler }>
                                    <div className='table-maximize'></div>
                                  </button>
                                  </div>
                                  {/* <button className={` ${dispaly_scatHeave ? 'display-on' : 'hidden-on'}`} onClick={ displayhandscatHeave}>{` ${dispaly_scatHeave ? 'すべて非表示' : 'すべて表示'}`}</button> */}
                                  <ResultHeave />
                            </div>
                        )}
                    </div>
                )}
              <div className='resize-container'>
                  <div className='result-graph-container '>
                  <div className='graph-title'>
                    <div className='graph-title-boxs'>
                      <p>{scat_chart_1}</p> 
                      <p>{scat_chart_2}</p> 
                      <p>{scat_chart_3}</p> 
                      {/* <button data-type='scat' onClick={ displayResizeHandler }>{`${displayScatResult ? 'v' : '>'}`}</button> */}
                    </div>
                    <button data-type='scatChart' onClick={ displayResizeHandler }>
                      <div className='table-maximize'></div>
                    </button>
                  </div>
                  <ResultScatChart />

                </div>
                </div>
                <div className='resize-container'>
                { df_scatHandling && (
                    <div className='dimension-chart-container result-graph-container '>
                      <div className='graph-title'>
                        <div>Multi dimension chart</div>
                          {/* <button className={`${dispaly_thirdgrah ? 'display-on' : 'hidden-on'}`} onClick={ displayhandthirdgrah }>{` ${dispaly_thirdgrah ? '非表示' : '表示'}`}</button> */}
                          <button data-type='dimensionchart' onClick={ displayResizeHandler }>
                            <div className='table-maximize'></div>
                          </button>
                      </div>
                      <ResultMultiChart />
                  </div>
                )}
                </div>
            </div>
            <ResultMaximize maximizeOpen={ maximizeOpen } setMaximizeOpen={ setMaximizeOpen } maximizeContent={ maximizeContent } setMaximizeContent={ setMaximizeContent } graphTitle={ maximizeTitle }/>
        </>
    )
}

export default ResultTrends;
