import React, { useEffect, useState } from 'react'
import Header from '../components/Header'

const StartSession = () => {
    
    const [sessionId, setSessionId] = useState(null)
    
    const startSession = async () => {
        try {
            const response = await fetch('/api/v1/sessions/start', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    sessionId: sessionId,
                    startDatetime: new Date()
                })
            });
            
            const json = await response.json();
            
            if (!response.ok) {
                throw new Error('セッション開始に失敗しました');
            }
            
            alert('セッションを開始しました');
        } catch (error) {
            console.error('エラー:', error);
            alert('システム管理者に連絡してください');
        }
    }

    return (
        <div style={{ margin: '0 20px', paddingBottom: '10px' }}>
            <Header/>
            <div style={{ color: 'white', padding: '20px', fontFamily: 'Arial, sans-serif' }}>
                <form onSubmit={(e) => { e.preventDefault(); startSession(); }} style={{ display: 'flex', flexDirection: 'column', gap: '10px', maxWidth: '400px', margin: 'auto' }}>
                    <div>
                        <label style={{fontSize: '20px'}}>セッションID:</label>
                        <input 
                            type="text" 
                            value={sessionId || ''} 
                            onChange={(e) => setSessionId(parseInt(e.target.value))} 
                            style={{ 
                                color: 'white', 
                                width: '100%', 
                                padding: '8px', 
                                borderRadius: '4px', 
                                border: '1px solid #ccc',
                                backgroundColor: 'transparent'
                            }} 
                        />
                    </div>
                    <button 
                        type="submit" 
                        style={{ 
                            padding: '10px', 
                            borderRadius: '4px', 
                            border: 'none', 
                            backgroundColor: '#4AFFFF', 
                            color: 'black', 
                            cursor: 'pointer', 
                            alignSelf: 'center',
                            width: '200px',
                            marginTop: '20px'
                        }}
                    >
                        セッション開始
                    </button>
                </form>
            </div>
        </div>
    )
}

export default StartSession