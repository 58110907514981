import React from 'react'
import { Dialog, DialogContent } from '@mui/material'

const ResultMaximize = (props) => {
    const handleCloseModal = () => {
        props.setMaximizeOpen(false)
        props.setMaximizeContent(null)
    }

    const paperProps = {
        sx: {
            color: 'white',
            backgroundColor: '#1e1e1e',

            maxWidth: '100%',
            maxHeight: '100%',
        }
    }

    const contentStyling = {
      overflow: 'hidden',
    };

    return (
        <Dialog className='maximize-container' open={ props.maximizeOpen } onClose={ handleCloseModal } PaperProps={ paperProps }>
            <DialogContent style={contentStyling}>
                <div className='result-graph-container w-100 resize-container'>
                    <div className='graph-title'>
                        <p>{props.graphTitle}</p>
                    </div>
                    <div className='graph-result-container '>
                        <div className='table-container'>
                            {props.maximizeContent}
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default ResultMaximize