import React, { useState, useEffect } from 'react'

import uploadFile from '../services/uploadFile'

import Header from '../components/Header'
import AdminDeployModel from '../components/AdminDeployModel'

import '../css/Optimize.css'
import '../css/admin.css'

import { useTranslation } from 'react-i18next'

const CoreAdmin = () => {
    const { t } = useTranslation(['core', 'general'])

    const [ ListTrack, setListTrack ] = useState([])
    const [ ListVehicle, setListVehicle ] = useState([])

    const [ selectedVehicle, setSelectedVehicle ] = useState('')
    const [ selectedTrack, setSelectedTrack ] = useState('')
    const [ defaultVSMModel, setDefaultVSMModel ] = useState()
    const [ doeSetting, setDoeSetting ] = useState()
    const [ parameterInfo, setParameterInfo ] = useState()
    const [ cornerName, setCornerName ] = useState()
    const [ cornerRange, setCornerRange ] = useState()
    const [ chartInfo, setChartInfo ] = useState()
    const [ comment, setComment ] = useState('')

    const [ showDeployPage, setShowDeployPage ] = useState(false)

    useEffect(() => {
        const setList = async () => {
            const vehicleList = await getList('vehicles')
            const trackList = await getList('circuits')
            setListVehicle(vehicleList)
            setListTrack(trackList)
        }

        setList()
    }, [])

    const handleInputChange = (e) => {
        const { name, value } = e.target

        if ((
            name === 'doe_condition'
            || name === 'doe_result' 
            || name === 'default_vsm_model'
            || name === 'doe_output'
            || name === 'doe_setting'
            || name === 'parameter_info'
            || name === 'corner_name'
            || name === 'corner_range'
            || name === 'chart_info'
        ) && !value) return

        switch (name) {
            case 'default_vsm_model':
                setDefaultVSMModel(e.target.files[0])
                break
            case 'doe_setting':
                setDoeSetting(e.target.files[0])
                break
            case 'parameter_info':
                setParameterInfo(e.target.files[0])
                break
            case 'corner_name':
                setCornerName(e.target.files[0])
                break
            case 'corner_range':
                setCornerRange(e.target.files[0])
                break
            case 'chart_info':
                setChartInfo(e.target.files[0])
                break
            case 'comment':
                setComment(value)
                break
            default:
                break
        }
    }

    const uploadFiles = async () => {
        const dataToUpload = { defaultVSMModel, doeSetting, parameterInfo, cornerName, cornerRange, chartInfo }
        const tempFileNames = {}
        for (const [type, file] of Object.entries(dataToUpload)) {
            const formData = new FormData()
            formData.append('file', file)

            // TODO n: change to actual URL
            const url = "/api/v1/files"
            const fileName = await uploadFile(formData, url)
            tempFileNames[type] = fileName
        }

        return tempFileNames
    }

    const startDefaultPipeline = async () => {
        if (!selectedVehicle || !selectedTrack || !defaultVSMModel || !doeSetting || !parameterInfo || !cornerName || !cornerRange || !chartInfo) {
            return alert(t('admin.zipHandlerAlert'))
        }

        const tempFileNames = await uploadFiles()

        fetch('/api/v1/pipelines/default', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                vehicleName: selectedVehicle,
                circuitName: selectedTrack,
                baseModel: tempFileNames.defaultVSMModel,
                doeSetting: tempFileNames.doeSetting,
                parameterCombination: tempFileNames.parameterInfo,
                circuitCornerName: tempFileNames.cornerName,
                circuitCornerRange: tempFileNames.cornerRange,
                chart: tempFileNames.chartInfo,
                comment: comment,
            })
        })
        .then(response => response.json())
        .then(response => {
            if (response.status === 200) {
                alert('success')
            }
        })
        .catch(error => {
            alert('error')
        })
    }

    const removeAllJobs = () => {
        fetch('/api/v1/pipelines', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(response => response.json())
        .then(response => {
            if (response.status === 200) {
                alert('success removing jobs')
            }
        })
        .catch(error => {
            alert('error deleting jobs')
        })
    }

    return (
        <>
            <Header />
            <div className='core-admin button-container'>
                <button onClick={ () => setShowDeployPage(!showDeployPage) }>
                    { showDeployPage ? 'Defaultモデル作成' : 'Deploy Model' }
                </button>
            </div>
            { showDeployPage ? <AdminDeployModel /> :
                <>
                    <div className='core-admin input-container'>
                        <div className='select-container'>
                            <div className='select-child'>
                                <span className='select-title'>{t('admin.carType')}</span>
                                <select
                                    value={ selectedVehicle }
                                    data-type='vehicle'
                                    onChange={(e) => setSelectedVehicle(e.target.value) }
                                >
                                    <option value='' disabled>{t('pleaseSelect')}</option>
                                    {ListVehicle.map(option => (
                                        <option key={`${option}_${Math.floor(Math.random() * 999)}`} value={option.name}>
                                            {option.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className='select-container'>
                            <div className='select-child'>
                                <span className='select-title'>{t('admin.course')}</span>
                                <select
                                    value={ selectedTrack }
                                    data-type='track'
                                    onChange={(e) => setSelectedTrack(e.target.value) }
                                >
                                    <option value='' disabled>{t('pleaseSelect')}</option>
                                    {ListTrack.map(option => (
                                        <option key={`${option}_${Math.floor(Math.random() * 999)}`} value={option.name}>
                                            {option.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className='settings-content'>
                            <div className='settings-title'>Default_VSM_Model</div>
                            <div className='settings-input'>
                                <input type='file' accept='.jve5' name='default_vsm_model' onChange={ handleInputChange } />
                            </div>
                        </div>
                        <div className='settings-content'>
                            <div className='settings-title'>DOE_Setting</div>
                            <div className='settings-input'>
                                <input type='file' accept='.csv' name='doe_setting' onChange={ handleInputChange } />
                            </div>
                        </div>
                        <div className='settings-content'>
                            <div className='settings-title'>Parameter_Info</div>
                            <div className='settings-input'>
                                <input type='file' accept='.json' name='parameter_info' onChange={ handleInputChange } />
                            </div>
                        </div>
                        <div className='settings-content'>
                            <div className='settings-title'>Corner_Name</div>
                            <div className='settings-input'>
                                <input type='file' accept='.json' name='corner_name' onChange={ handleInputChange } />
                            </div>
                        </div>
                        <div className='settings-content'>
                            <div className='settings-title'>Corner_Range</div>
                            <div className='settings-input'>
                                <input type='file' accept='.csv' name='corner_range' onChange={ handleInputChange } />
                            </div>
                        </div>
                        <div className='settings-content'>
                            <div className='settings-title'>Chart_Info</div>
                            <div className='settings-input'>
                                <input type='file' accept='.json' name='chart_info' onChange={ handleInputChange } />
                            </div>
                        </div>
                        <div className='settings-content'>
                            <div className='settings-title'>Comment</div>
                            <div className='settings-input'>
                                <textarea name='comment' value={ comment } onChange={ handleInputChange } />
                            </div>
                        </div>
                    </div>
                    <div className='core-admin button-container'>
                        <button onClick={ startDefaultPipeline }>
                            Start Default Pipeline
                        </button>
                        <button onClick={ removeAllJobs }>
                            Remove all jobs
                        </button>
                    </div>
                </>
            }
        </>
    )

    function getList(listType) {
        return new Promise ((resolve, reject) => {
            fetch(`/api/v1/${listType}`)
                .then(response => response.json())
                .then(data => resolve(data[listType]))
                .catch(error => reject(error))
        })
    }
}

export default CoreAdmin
