import React, { useState, useEffect } from 'react'
import * as mat4js from 'mat-for-js'

import CornerChart from './CornerChart'
import SelectBoxLabels from './SelectBoxLabels'
import TimeSeriesChartTimeDistance from './TimeSeriesChart_TimeDistance'

const SetupResultRight = (props) => {
    const [ channelNameList, setChannelNameList ] = useState([])

    const [ timeSeriesList, setTimeSeriesList ] = useState([])
    const [ graphData, setGraphData ] = useState()

    const [ selectedChannel, setSelectedChannel ] = useState('GPS_Speed')
    const [ selectedCornerChart, setSelectedCornerChart ] = useState()

    // const timeSeriesFilePath = props.timeSeriesFilePath
    const setupTimeSeriesFilePath = props.setupTimeSeriesFilePath
    // const timeSeriesType = props.timeSeriesType
    const channelNameRow = props.channelNameRow
    const firstDataRow = props.firstDataRow

    const Obj_chart_combination = props.Obj_chart_combination
    const Obj_KPI_base = props.Obj_KPI_base
    const Obj_KPI_recommend = props.Obj_KPI_recommend
    const Obj_KPI_selected = props.Obj_KPI_selected
    const Obj_circuit_sens = props.Obj_circuit_sens

    useEffect(() => {
        const getFile = async (modelId) => {            
            return fetch(`api/v1/files/timeSeries?modelId=${modelId}`)
                .then(response => {
                    return response.json();
                })
                .then(response => {
                    if (response.status !== 200) {
                        return { readData: null, timeSeriesType: null }
                    }

                    if (response.ext === 'mat') {
                        const uint8Array = new Uint8Array(response.data.data);
                        const arrayBuffer = uint8Array.buffer;
                        return { readData: arrayBuffer, timeSeriesType: 'mat' }
                    }

                    return { readData: response.data, timeSeriesType: response.ext }
                })
        }

        const getTimeSeriesData = async (timeSeriesFilePath) => {
            try {
                let timeSeriesContent, channelNames

                const { readData, timeSeriesType } = await getFile(timeSeriesFilePath)

                if (timeSeriesType === 'mat') {
                    const matContent = mat4js.read(readData)
                    timeSeriesContent = matContent.data
                    channelNames = Object.keys(matContent.data)
                } else {
                    let splitData

                    if (timeSeriesType === 'csv') {
                        splitData = readData.split(/\r?\n/)
                        splitData.pop()

                        const headers = splitData[channelNameRow - 1].split(/,/)
                        channelNames = headers.map(header => header.replace(/"/g, ''))
                        headers.pop()
                    } else if (timeSeriesType === 'txt') {
                        splitData = readData.split(/\r?\n/).filter(line => line[0] !== '#')

                        const headers = splitData[0].split(/\t/)
                        channelNames = headers.map(header => header.replace(/"/g, ''))
                    }

                    timeSeriesContent = splitData
                }

                return { timeSeriesContent, channelNames, timeSeriesType }
            } catch (error) {
                console.log("error", error)
                throw new Error('Error getting time series data')
            }
        }

        const fetchData = () => {
            const tempTimeSeriesList = []
            const tempPlotData = {
                Distance: [],
                Time: [],
                [selectedChannel]: []
            }
            setupTimeSeriesFilePath.forEach(async (filePath, i) => {
                try {
                    const timeSeriesResult = await getTimeSeriesData(filePath)
                    if (!timeSeriesResult.timeSeriesContent) return
                    const processedData = processData(timeSeriesResult.timeSeriesContent, timeSeriesResult.channelNames, timeSeriesResult.timeSeriesType)

                    const fileName = filePath.split('/').pop()
    
                    tempTimeSeriesList.push({
                        fileName: fileName,
                        visible: true,
                        color: i === 0 ? '#f8ea2c' : '#a20401',
                        timeSeriesData: processedData,
                        channelNameList: timeSeriesResult.channelNames
                    })

                    tempPlotData.Distance.push({ data: processedData.Distance, axis: 'x', fileName: fileName })
                    tempPlotData.Time.push({ data: processedData.Time, axis: 'xTime', fileName: fileName })
                    tempPlotData[selectedChannel].push({ data: processedData[selectedChannel], axis: 'y', fileName: fileName })

                    setChannelNameList(timeSeriesResult.channelNames)
                } catch (error) {
                    throw new Error('Error getting time series data')
                }
            })

            setTimeSeriesList(tempTimeSeriesList)
            setGraphData({
                channels: [{
                    x: 'Distance',
                    xTime: 'Time',
                    y: selectedChannel
                }],
                plotData: tempPlotData
            })
        }
        fetchData()
    }, [setupTimeSeriesFilePath])

    useEffect(() => {
        if (timeSeriesList.length === 0) return

        const tempPlotData = {
            Distance: graphData.plotData.Distance,
            Time: graphData.plotData.Time,
            [selectedChannel]: []
        }

        timeSeriesList.forEach((timeSeries, i) => {
            const { fileName, timeSeriesData } = timeSeries
            tempPlotData[selectedChannel].push({ data: timeSeriesData[selectedChannel], axis: 'y', fileName })
        })

        setGraphData({
            channels: [{
                x: 'Distance',
                xTime: 'Time',
                y: selectedChannel
            }],
            plotData: tempPlotData
        })
    }, [selectedChannel])

    const processData = (readTimeSeriesData, headers, timeSeriesType) => {
        const processMat = () => {
            const processedData = {}

            headers.forEach(header => {
                processedData[header] = Array.isArray(readTimeSeriesData[header]) ? readTimeSeriesData[header].map(d => d[1]) : readTimeSeriesData[header]
            })

            return processedData
        }

        const processCsvOrTxt = (splitter, firstDataRow) => {
            const processedData = {}
            const keyIndex = {}

            headers.forEach(header => {
                keyIndex[header] = headers.indexOf(header)
                processedData[header] = []
            })
            
            for (let i = firstDataRow; i < readTimeSeriesData.length; i++) {
                const line = readTimeSeriesData[i].split(splitter)

                headers.forEach(header => {
                    processedData[header].push(Number(line[keyIndex[header]]))
                })
            }

            return processedData
        }

        let processedData
        if (timeSeriesType === 'mat') {
            processedData = processMat()
        } else if (timeSeriesType === 'csv') {
            processedData = processCsvOrTxt(/,/, firstDataRow - 1)
        } else if (timeSeriesType === 'txt') {
            processedData = processCsvOrTxt(/\t/, 1)
        }

        return processedData
    }

    return (
        <div className='result-container right'>
            <div className='top'>
                { Object.keys(Obj_KPI_base).length > 0 && (
                    <>
                        <div className='chart-container'>
                            <CornerChart
                                Obj_chart_combination={ Obj_chart_combination }
                                Obj_KPI_base={ Obj_KPI_base }
                                Obj_KPI_recommend={ Obj_KPI_recommend }
                                Obj_KPI_selected={ Obj_KPI_selected }
                                Obj_circuit_sens={ Obj_circuit_sens }
                                isSetup={ true }
                                isCornerSensitivity={ false }
                                selectedCornerChart={ selectedCornerChart }
                                setSelectedCornerChart={ setSelectedCornerChart }
                            />
                        </div>
                    </>
                )}
            </div>
            <div className='bottom'>
                { timeSeriesList.length && graphData && 
                    <>
                        <div className='select-container'>
                            <SelectBoxLabels title='' onChangeType='channel' setState={ setSelectedChannel } options={ channelNameList } value={ selectedChannel }/>
                        </div>
                        <div className='chart-container'>
                            <TimeSeriesChartTimeDistance
                                timeSeriesList={ timeSeriesList }
                                graphData={ graphData }
                                isSetup={ true }
                            />
                        </div>
                    </>
                }
                
            </div>
        </div>
    )
}

export default SetupResultRight