import React, { useState, useContext, useEffect } from 'react'

import AuthContext from '../../authContext'

import '../css/mainButtonDropdown.css'

import { useTranslation } from 'react-i18next'

const MainButtonDropdown = (props) => {
    const { i18n, t } = useTranslation(['core'])

    const [ ListTrack, setListTrack ] = useState([])
    const [ ListVehicle, setListVehicle ] = useState([])

    const [ dropdownItems, setDropdownItems ] = useState([])
    const [ subDropdownItems, setSubDropdownItems ] = useState([])

    const ctx = useContext(AuthContext)
    const role = ctx.role

    const mainButtonId = props.id
    const mainButtonTitle = props.title
    const setSelectedType = props.setSelectedType

    useEffect(() => {
        const setList = async () => {
            const vehicleList = await getList('vehicles')
            const trackList = await getList('circuits')
            setListVehicle(vehicleList)
            setListTrack(trackList)
        }

        setList()
    }, [])

    useEffect(() => {
        getDropdownItems(mainButtonId)
    }, [ListTrack, ListVehicle])

    useEffect(() => {
        getDropdownItems(mainButtonId)
    }, [mainButtonId, role])

    const getDropdownItems = (mainButtonId) => {
        if (ListVehicle === undefined || ListTrack === undefined) return

        if (mainButtonId === 'optimize' || mainButtonId === 'time') {
            // TODO: filter track&vehicle by user?
            setDropdownItems(ListVehicle)
            setSubDropdownItems(ListTrack)
        } else if (mainButtonId === 'pwBop') {
            setDropdownItems([{id: 1, name: 'GT World Challenge Asia'}, {id: 2, name: 'SuperFormula'}, {id: 3, name: 'Super GT'}])
            setSubDropdownItems([{id: 1, name: '2023'}, {id: 2, name: '2024'}, {id: 3, name: '2025'}])
        } else if (mainButtonId === 'option') {
            setDropdownItems([{id: 1, name: t('mainButtonDropdown.manualDL')}])
        }
    }

    // Has to be added here, since getDropdownItems sets manualDL as a string, which makes it not changeable automatically, it seems
    i18n.on('languageChanged', () => {
        getDropdownItems(mainButtonId)
    })

    const dropdownButtonHandler = (e) => {
        const targetElement = e.target
        const dropdownContent = targetElement.nextElementSibling

        if (dropdownContent.classList.contains('show')) {
            dropdownContent.classList.remove('show')
            targetElement.classList.remove('active')
            dropdownContent.nextElementSibling?.classList.remove('show')
            return
        }

        document.querySelector('.dropdown-content.show')?.classList.remove('show')
        document.querySelector('.dropdown-button.active')?.classList.remove('active')
        document.querySelector('.sub-dropdown-content.show')?.classList.remove('show')

        dropdownContent.classList.toggle('show')
        targetElement.classList.toggle('active')
    }

    const dropdownItemHandler = (e) => {
        const targetElement = e.target
        const parentElement = targetElement.parentElement
        const type = parentElement.parentElement.dataset.id

        const selectedValue = targetElement.dataset.id
        parentElement.dataset.selectedValue = selectedValue

        if (parentElement.nextElementSibling) {
            parentElement.nextElementSibling.classList.toggle('show')
        } else {
            parentElement.classList.remove('show')
        }

        if (type === 'option') {
            if (selectedValue === t('mainButtonDropdown.manualDL')) {
                const BASE_URL = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`

                const a = document.createElement('a')
                a.href = `${BASE_URL}/data/Manual_GRIDCORE_v03.pdf`
                a.download = 'Manual_GRIDCORE_v03.pdf'
                a.target = '_blank'
                a.click()
                a.remove()
            }
        } else {
            setSelectedType(type)
            if (type === 'optimize' || type === 'time') {
                props.setVehicle(targetElement.dataset.name)
                props.setVehicleData(ListVehicle.find((vehicle) => { return vehicle.id === Number(selectedValue) }))
                props.setTrack(null)
            } else if (type === 'pwBop') {
                props.setRaceSeries(selectedValue)
            }
        }
    }

    const subDropdownItemHandler = (e) => {
        const targetElement = e.target
        const parentElement = targetElement.parentElement
        const type = parentElement.parentElement.dataset.id

        parentElement.dataset.selectedSubValue = targetElement.dataset.id
        parentElement.classList.remove('show')

        parentElement.previousElementSibling.classList.remove('show')
        
        if (type === 'optimize' || type === 'time') {
            props.setTrack(targetElement.dataset.name)
            props.setTrackData(ListTrack.find((track) => { return track.id === Number(targetElement.dataset.id) }))
        } else if (type === 'pwBop') {
            props.setRaceYear(targetElement.dataset.id)
        }
    }

    return (
        <div className='main-button-dropdown select-container' data-id={ mainButtonId } id={ `main-button-dropdown-${mainButtonId}` } >
            <div className='main-button dropdown-button' onClick={ dropdownButtonHandler }>{ mainButtonTitle }</div>
            <div className='dropdown-content'>
                { dropdownItems.map((item, index) => {
                    return (
                        <div className='dropdown-item' key={ index } data-id={ item.id } data-name={ item.name } onClick={ dropdownItemHandler }>{ item.name }</div>
                    )
                }) }
            </div>
            { subDropdownItems.length > 0 &&
                <div className='sub-dropdown-content'>
                    { subDropdownItems.map((item, index) => {
                        return (
                            <div className='dropdown-item' key={ index } data-id={ item.id } data-name={ item.name } onClick={ subDropdownItemHandler }>{ item.name }</div>
                        )
                    }) }
                </div>
            }
        </div>
    )
}

export default MainButtonDropdown

function getList(listType) {
    return new Promise ((resolve, reject) => {
        fetch(`/api/v1/${listType}`)
            .then(response => response.json())
            .then(data => resolve(data[listType]))
            .catch(error => reject(error))
    })
}