import * as ort from 'onnxruntime-web'

//ScatterのObjectデータ作成
const makeScatONNXobject = (df_DOEcondition, df_baseSetup, DOEnum, Obj_parameter_combination) => {
    console.log("makeScatONNXobject input", {df_DOEcondition, df_baseSetup, DOEnum, Obj_parameter_combination})
    const Obj_scatSetup = {}

    for (let i = 0; i < df_DOEcondition.index.length; i++) {
        const judgeConst = df_baseSetup.iloc({'rows':[i], 'columns':[0]}).values[0][0]
        // Max の値？
        const judgeType = df_DOEcondition.iloc({'rows':[i], 'columns':[1]}).values[0][0]

        if (judgeConst === 'ON') {
            const randDOE = []
            const valueDOE = df_baseSetup.iloc({'rows':[i], 'columns':[1]}).values[0][0]

            for (let j = 0; j < DOEnum; j++) {
                randDOE[j] = valueDOE
            }

            let tensor_input
            if(typeof(valueDOE) === 'string') {
                tensor_input = new ort.Tensor('string', randDOE, [randDOE.length, 1])
            } else {
                tensor_input = new ort.Tensor('float32', randDOE, [randDOE.length, 1])
            }

            Obj_scatSetup['x' + (i+1)] = tensor_input
        } else if (judgeConst === '-') {
            if (judgeType === '-') {
                const randDOE = []
                const categoryValues = df_DOEcondition.iloc({'rows':[i], 'columns':['3:']}).values[0].filter(element => !(element === '-'))
                const max = categoryValues.length
                let randValue

                for (let j = 0; j < DOEnum; j++) {
                    randValue = Math.floor(Math.random() * max)
                    randDOE[j] = categoryValues[randValue]
                }

                    let tensor_input = new ort.Tensor('string', randDOE, [randDOE.length, 1])
                    Obj_scatSetup['x' + (i+1)] = tensor_input
            } else {
                const randDOE = []
                let parameterName
                let randValue

                for(const key in Obj_parameter_combination){
                    if(df_baseSetup.index[i] === key){
                        parameterName = key
                    }
                }

                if (!(parameterName === undefined)){
                    const max = Obj_parameter_combination[parameterName].length
                    for (let k = 0; k < DOEnum; k++) {
                        randValue = Math.floor(Math.random() * max)
                        randDOE[k] = Obj_parameter_combination[parameterName][randValue].value
                    }
                }else{
                    const min = df_DOEcondition.iloc({'rows':[i], 'columns':[1]}).values[0][0]
                    const max = df_DOEcondition.iloc({'rows':[i], 'columns':[2]}).values[0][0]
            
                    for (let k = 0; k < DOEnum; k++) {
                        randDOE[k] = Math.random() * (max-min) + min
                    }
                }
                
                let tensor_input = new ort.Tensor('float32', randDOE, [randDOE.length, 1])
                Obj_scatSetup['x' + (i + 1)] = tensor_input
            }
        } else if (judgeConst === 'env') {
            const randDOE = []
            const valueDOE = df_baseSetup.iloc({'rows':[i], 'columns':[1]}).values[0][0]

            for (let i = 0; i < DOEnum; i++) {
                randDOE[i] = valueDOE
            }

            let tensor_input = new ort.Tensor('float32', randDOE, [randDOE.length, 1])
            Obj_scatSetup['x' + (i + 1)] = tensor_input
        } else {
            const indexOther = df_DOEcondition.query(df_DOEcondition['Parameter'].eq(judgeConst)).index[0]

            let tensor_input
            if (df_DOEcondition.iloc({'rows':[indexOther], 'columns':[1]}).values[0][0] === '-') {
                tensor_input = new ort.Tensor('string', Obj_scatSetup['x' + (indexOther + 1)].data, Obj_scatSetup['x' + (indexOther + 1)].dims)
            } else {
                tensor_input = new ort.Tensor('float32', Obj_scatSetup['x' + (indexOther + 1)].data, Obj_scatSetup['x' + (indexOther + 1)].dims)
            }

            Obj_scatSetup['x' + (i + 1)] = tensor_input
        }
    }

    return Obj_scatSetup
}

export default makeScatONNXobject