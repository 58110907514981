const Login = () => {
    const redirectTo = window.location.href.replace(window.location.origin, "");
    if (redirectTo === "/") {
        window.location.href = `/login?redirect=${encodeURIComponent("/top")}`;
        return;
    }
    window.location.href = `/login?redirect=${encodeURIComponent(redirectTo)}`;
}

export default Login
