import { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'

import Header from '../components/Header'
import ModalComponent from '../components/ModalComponent'

import '../css/adminPage.css'

import { useTranslation } from 'react-i18next'

const Admin = () => {
    const { t } = useTranslation(['strategy', 'general'])

    const startDate = useRef()
    const raceStartTime = useRef()
    const lapRecordingStartTime = useRef()
    const endDate = useRef()
    const raceEndTime = useRef()
    const lapRecordingEndTime = useRef()
    const [ raceId, setRaceId ] = useState()
    const [ raceName, setRaceName ] = useState()
    const [ raceDetailName, setRaceDetailName ] = useState()
    const [ circuit, setCircuit ] = useState()
    const [ sectorLength, setSectorLength ] = useState()
    const [ raceTime, setRaceTime ] = useState()
    const [ raceType, setRaceType ] = useState('race')
    const [ numOfLaps, setnumOfLaps ] = useState(1)
    const [ raceDetailId, setRaceDetailId ] = useState(null)

    const [ raceSeries, setRaceSeries ] = useState([])
    const [ circuitList, setCircuitList ] = useState([])
    const [ eventList, setEventList ] = useState([])
    const [ eventListData, setEventListData ] = useState([])
    const [ raceDetailList, setRaceDetailList ] = useState([])
    const [ editEventStatus, setEditEventStatus ] = useState()
    const [ event, setEvent ] = useState()

    const [ modalType, setModalType ] = useState()
    const [ openModal, setOpenModal ] = useState(false)

    useEffect(() => {
        const getSeries = () => {
            fetch('/api/race/getAll')
            .then(response => response.json())
            .then(json => {
                if (json.status === 200) {
                    let data = json.data
                    const list = data.map((d, index) => (
                        <option key = { index } value = { d.ID } data-name = { d.NAME }>{ d.DISPLAY_NAME || d.NAME }</option>
                    ))

                    setRaceSeries(list)
                }
            })
            .catch((error) => {
                console.log(error)
                alert(t('pleaseContactAdmin', {ns: 'general'}))
            })
        }

        const getCircuits = () => {
            fetch('/api/circuit/getAll')
            .then(response => response.json())
            .then(json => {
                if(json.status === 200) {
                    let data = json.data
                    const list = data.map((d, index) => (
                        <option key = { index } value = { d.ID } data-sectorlength = { d.SECTOR_LENGTH }>{ d.NAME }</option>
                    ))

                    setCircuitList(list)
                }
            })
            .catch((error) => {
                console.log(error)
                alert(t('pleaseContactAdmin', {ns: 'general'}))
            })
        }

        getSeries()
        getCircuits()
        getEvents()
    }, [])

    useEffect(() => {
        if (raceSeries.length > 0 && circuitList.length > 0) {
            setRaceId(raceSeries[0].props.value)
            setRaceName(raceSeries[0].props['data-name'])
            setCircuit(circuitList[0].props.value)
            setSectorLength(circuitList[0].props['data-sectorlength'])
        }
    }, [ raceSeries, circuitList ])

    useEffect(() => {
        getEvents()
    }, [ editEventStatus ])
    
    const getEvents = () => {
        fetch('/api/event/getAll')
        .then(response => response.json())
        .then(json => {
            if (json.status === 200) {
                let data = json.data
                setEventListData(data)
                const list = data.map((d, index) => (
                    <option key = { index } value = { d.ID }>{ d.EVENT_NAME }</option>
                ))

                setEventList(list)
            }
        })
        .catch((error) => {
            console.log(error)
            alert(t('pleaseContactAdmin', {ns: 'general'}))
        })
    }

    const getAllRacesByEventId = (eventId) => {
        fetch(`/api/raceDetail/getAllRacesByEventId/${eventId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(response => response.json())
        .then(json => {
            if (json.status === 200) {
                let data = json.data
                const list = data.map((d, index) => (
                    <option key = { index } value = { d.ID }>{ d.RACE_DETAIL_NAME }</option>
                ))

                setRaceDetailList(list)
            }
        })
        .catch((error) => {
            console.log(error)
            alert(t('pleaseContactAdmin', {ns: 'general'}))
        })
    }

    const updateEventList = (updatedEventList) => {
        const list = updatedEventList.map((d, index) => (
            <option key = { index } value = { d.value }>{ d.children }</option>
        ))
        setEventList(list)
    }

    const raceDetailNameHandler = (e) => {
        setRaceDetailName(e.target.value)
    }

    const raceTypeHandler = (e) => {
        setRaceType(e.target.value)
    }

    const numOfLapsHandler = (e) => {
        setnumOfLaps(e.target.value)
    }

    const raceTimeHandler = (e) => {
        setRaceTime(e.target.value)
    }

    const eventHandler = (e) => {
        if (e.target.value === 'addEvent') {
            setModalType('addNewEvent')
            setOpenModal(true)
        } else if(e.target.value !== 'notSelected') {
            setEvent(e.target.value)
            getAllRacesByEventId(Number(e.target.value))
            const eventInfo = eventListData.find((event) => event.ID === Number(e.target.value))
            const eventSectorLength = circuitList.find((circuit) => circuit.props.value === eventInfo.CIRCUIT_ID).props['data-sectorlength']
            const eventRaceIdName = raceSeries.find((series) => series.props.value === eventInfo.RACE_ID).props['data-name']
            setCircuit(eventInfo.CIRCUIT_ID)
            setSectorLength(eventSectorLength)
            setRaceId(eventInfo.RACE_ID)
            setRaceName(eventRaceIdName)
        } else if(e.target.value === 'notSelected') {
            setEvent(undefined)
        }
    }

    const raceDetailHandler = (e) => {
        if(e.target.value !== 'notSelected') {
            setRaceDetailId(e.target.value)
        } else if(e.target.value === 'notSelected') {
            setRaceDetailId(undefined)
        }
    }

    const handeEventAdded = (eventName, eventCircuit, eventSeries) => {        
        if (eventName === '') {
            alert(t('admin.pleaseEnterEventName'))
            return
        } else if (eventList.find((event) => event.props.children === eventName)) {
            alert(t('admin.sameNameExists'))
            return
        } else {
            if (eventCircuit === '' || eventSeries === '' ) {
                alert(t('admin.pleaseSelectSeriesOrCircuit'))
                return
            } else {
                setOpenModal(false)
                fetch('/api/event/addEvent', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        eventName,
                        circuitId: eventCircuit,
                        raceId: eventSeries
                    })
                })
                .then(response => response.json())
                .then(json => {
                    if (json.status !== 200) {
                        return alert(t('pleaseContactAdmin', {ns: 'general'}))
                    }

                    alert(t('admin.addedEvent'))
                    getEvents()
                })
                .catch((error) => {
                    console.log(error)
                    alert(t('pleaseContactAdmin', {ns: 'general'}))
                })
                getEvents()
            }
        }
    }

    const editEventHandler = () => {
        setModalType('editEvent')
        setOpenModal(true)
    }

    const handleCloseModal = () => {
        setOpenModal(false)
    }

    const formHandler = (e) => {
        e.preventDefault()

        if(startDate === undefined || raceStartTime === undefined || lapRecordingStartTime === undefined || endDate === undefined || raceEndTime === undefined || lapRecordingEndTime === undefined || raceDetailName === undefined) {
            return alert(t('admin.pleaseCheckForm'))
        }

        if(event === undefined) {
            return alert(t('general.pleaseSelectEvent'))
        }

        if(startDate > endDate) {
            return alert(t('admin.pleaseCheckStartOrEndDate'))
        }
        else if((startDate === endDate) && (raceStartTime > raceEndTime)) { 
            return alert(t('admin.pleaseCheckStartOrEndTime'))
        }

        fetch('/api/raceDetail/addRace', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                startDate: startDate.current.value,
                raceStartTime: raceStartTime.current.value,
                lapRecordingStartTime: lapRecordingStartTime.current.value,
                endDate: endDate.current.value,
                raceEndTime: raceEndTime.current.value,
                lapRecordingEndTime: lapRecordingEndTime.current.value,
                raceId,
                raceName,
                raceDetailName,
                circuit,
                sectorLength,
                raceType,
                numOfLaps: numOfLaps || null,
                eventId: event || 1,
                raceTime: raceTime || null,
                raceDetailId
            })
        })
        .then(response => response.json())
        .then(json => {
            if (json.status !== 200) {
                return alert(t('pleaseContactAdmin', {ns: 'general'}))
            }

            alert(t('admin.createSession'))
        })
        .catch((error) => {
            console.log(error)
            alert(t('pleaseContactAdmin', {ns: 'general'}))
        })
    }

    const stopScrapingHandler = () => {
        fetch('/api/raceDetail/endRace', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`)
            }
            return response.json()
        })
        .then(json => {
            alert(t('admin.stoppedScraping'))
        })
        .catch((error) => {
            console.log(error)
            alert(t('pleaseContactAdmin', {ns: 'general'}))
        })
    }
    

    return (
        <>
            <Header/>
            <div className = 'main-wrap'> 
                <div className = 'admin-form-small'>
                    <Link to = '/event'>
                        <button type='button' className = 'dash-link'>{t('admin.toRaceList')}
                            <span className='top-page-dec-top-left'></span>
                            <span className='top-page-dec-top-right'></span>
                            <span className='top-page-dec-bottom-left'></span>
                            <span className='top-page-dec-bottom-right'></span>
                        </button>
                    </Link>
                    <form className = 'admin-form' onSubmit = { formHandler }>
                        <h2>{t('admin.creatingSession')}</h2>
                        <div className = 'form-boxs'>
                            <div className = 'form-date'>
                                <div className = 'form-input'>
                                    <div className='form-text'>{t('general.startDate')}:<span><span>*</span></span></div>
                                    <input type='date' ref = { startDate }></input>
                                </div>
                                <div className = 'form-input'>
                                    <div className='form-text'>{t('general.raceStartTime')}:<span><span>*</span></span></div>
                                    <input type='time' ref = { raceStartTime }></input>
                                </div>
                                <div className = 'form-input'>
                                    <div className='form-text'>{t('general.lapRecordingStartTime')}:<span><span>*</span></span></div>
                                    <input type='time' ref = { lapRecordingStartTime }></input>
                                </div>
                            </div>

                            <div className = 'form-date'>
                                <div className = 'form-input'>
                                    <div className='form-text'>{t('general.endDate')}:<span><span>*</span></span></div>
                                    <input type='date' ref = { endDate }></input>
                                </div>
                                <div className = 'form-input'>
                                    <div className='form-text'>{t('general.raceEndTime')}:<span>*</span></div>
                                    <input type='time' ref = { raceEndTime }></input>
                                </div>
                                <div className = 'form-input'>
                                    <div className='form-text'>{t('general.lapRecordingEndTime')}:<span>*</span></div>
                                    <input type='time' ref = { lapRecordingEndTime }></input>
                                </div>
                            </div>

                            <div className = 'form-raceinfo'>
                                <div className = 'form-input'>
                                    <div className='form-text'>{t('admin.raceName')}:<span>*</span></div>
                                    <input name='raceName' type='string' onChange = { raceDetailNameHandler } maxLength = {40}></input>
                                </div>
                                <div className = 'form-input from-select'>
                                    <div className='form-text'>{t('admin.raceType')}:<span>*</span></div>
                                    <select name='raceType' onChange = { raceTypeHandler }>
                                        <option value='race'>RACE</option>
                                        <option value='fp'>FP</option>
                                        <option value='qf'>QF</option>
                                    </select>
                                </div>
                                <div className = 'form-input'>
                                    <div className='form-text'>{t('admin.lapCount')}:</div>
                                    <input name='raceLaps' type='number' onChange = { numOfLapsHandler } maxLength = {5}></input>
                                </div>

                                <div className = 'form-input from-select'>
                                    <div className='form-text'>{t('admin.sessionTime')}:</div>
                                    <select onChange={ raceTimeHandler }>
                                        <option value=''>N/A</option>
                                        <option value='03:00'>3{t('admin.hours')}</option>
                                        <option value='04:00'>4{t('admin.hours')}</option>
                                        <option value='05:00'>5{t('admin.hours')}</option>
                                        <option value='24:00'>24{t('admin.hours')}</option>
                                    </select>
                                </div>
                            </div>

                            <div className = 'form-condition'>
                                <div className='form-input from-select'>
                                    <div className='form-text'>{t('admin.addToRaceList')}:<span>*</span></div>
                                    <select name='event' onChange = { eventHandler }>
                                        <option key = { eventList.length + 1 } value = 'notSelected'>{t('pleaseSelect', {ns: 'general'})}</option>
                                        { eventList }
                                        <option key = { eventList.length + 2 } value = 'addEvent'>{t('admin.addEventInfo')}</option>
                                    </select>
                                </div>

                                <div className='form-input from-select'>
                                    <div className='form-text'>{t('admin.specifyExisting')}:</div>
                                    <select name='raceDetail' onChange = { raceDetailHandler }>
                                        <option key = { raceDetailList.length + 1 } value = 'notSelected'>{t('admin.doNotSelectWhenNew')}</option>
                                        { raceDetailList }
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className = 'btn-area'>
                            <div className = 'action-area'>
                                <button type='submit' id = 'scraping-start' className = 'form-btn action-btn'>
                                {t('admin.createOrUpdateSession')}
                                    <span className='top-page-dec-top-left'></span>
                                    <span className='top-page-dec-top-right'></span>
                                    <span className='top-page-dec-bottom-left'></span>
                                    <span className='top-page-dec-bottom-right'></span>
                                </button> 
                                {/*<button type='button' id = 'scraping-stop' className = 'action-btn' onClick = { stopScrapingHandler }>
                                {t('admin.deleteAllScrapingJobs')}
                                    <span className='top-page-dec-top-left'></span>
                                    <span className='top-page-dec-top-right'></span>
                                    <span className='top-page-dec-bottom-left'></span>
                                    <span className='top-page-dec-bottom-right'></span>
                                </button> */}
                            </div>
                        </div>
                        </form>
                        
                        <div className='edits-area'>
                            <div>
                                <h3>{t('admin.raceList')}</h3>
                                <div className = 'race-edits'>
                                    <button type='button' onClick={ editEventHandler }>{t('admin.eventAndRaceEdit')}
                                        <span className='top-page-dec-top-left'></span>
                                        <span className='top-page-dec-top-right'></span>
                                        <span className='top-page-dec-bottom-left'></span>
                                        <span className='top-page-dec-bottom-right'></span>
                                    </button>
                                </div>
                            </div>
                            
                            <div>
                                <h3>{t('admin.databaseEdit')}</h3>
                                <div className = 'race-edits'>
                                    <Link to = '/editdatabase'>
                                    <button type='button'>{t('admin.timeEdit')}
                                        <span className='top-page-dec-top-left'></span>
                                        <span className='top-page-dec-top-right'></span>
                                        <span className='top-page-dec-bottom-left'></span>
                                        <span className='top-page-dec-bottom-right'></span>
                                    </button>
                                    </Link>
                                    <Link to='/editvehicledatabase'>
                                        <button type='button'>{t('admin.vehicleParamEdit')}
                                            <span className='top-page-dec-top-left'></span>
                                            <span className='top-page-dec-top-right'></span>
                                            <span className='top-page-dec-bottom-left'></span>
                                            <span className='top-page-dec-bottom-right'></span>
                                        </button>
                                    </Link>
                                    <Link to = '/startSession'>
                                    <button type='button'>{"session開始"}
                                        <span className='top-page-dec-top-left'></span>
                                        <span className='top-page-dec-top-right'></span>
                                        <span className='top-page-dec-bottom-left'></span>
                                        <span className='top-page-dec-bottom-right'></span>
                                    </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
        </div>

            <ModalComponent
                open = { openModal }
                onClose = { handleCloseModal }
                setOpenModal = { setOpenModal }
                modalType = { modalType }
                onEventAdded = { handeEventAdded }
                raceSeries = { raceSeries }
                circuitList = { circuitList }
                eventList = { eventList }
                eventListData = { eventListData }
                setEditEventStatus = { setEditEventStatus }
                updateEventList = { updateEventList }
                raceId = { raceId }
                circuit = { circuit }
            />
        </>
    )
}

export default Admin