const uploadFile = (formData, url = '/core/api/uploadFile') => {
    return new Promise((resolve, reject) => {
        fetch(url, {method: 'POST', body: formData })
            .then(response => response.json())
            .then(response => {
                if (response.status !== 200) reject(response)
                const fileName = response.message.split(" ")[0]
                resolve(fileName)
            })
            .catch(error => { reject(error) })
    })
}

export default uploadFile