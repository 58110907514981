import React, { useEffect, useState } from 'react'

import * as dfd from 'danfojs'
import { utils } from 'xlsx'

import '../css/OptimizeGraphResult.css'

import OptimizeSetupFile from './OptimizeSetupFile'
import Track from './Track'
import ResultPerformance from './ResultPerformance'
import ResultTrend from './ResultTrend'
import ResultSensitivity from './ResultSensitivity'

import startOptimize from '../services/startOptimize'
import uploadFile from '../services/uploadFile'

import compare_drivermap from '../services/compare_drivermap'
import compare_laptime from '../services/compare_laptime'
import compare_setupRequest from '../services/compare_setupRequest'
import compare_setupLapTime from '../services/compare_setupLapTime'
// import compare_rollstiff from '../services/compare_rollstiff'
import makeBaseDataframe from '../services/makeBaseDataframe'
import makeCompareONNXobject from '../services/makeCompareONNXobject'
import covertNameContinuous from '../services/covertNameContinuous'
import downloadResult from '../services/downloadResult'
import addCompareTimes from '../services/addCompareTimes'
import addKPI from '../services/addKPI'
import updateProgress from '../services/updateProgress'

import downloadBtn from '../image/download-icon.png'

import  { useTranslation } from 'react-i18next'

const Optimization = (props) => {
    const { t } = useTranslation(['core'])

    const [ selectedResult, setSelectedResult ] = useState('performance')
    const [ listDriver, setListDriver ] = useState()

    // const [ tuningFile, setTuningFile ] = useState('')
    // const [ resultFile, setResultFile ] = useState('')

    // const [ groupedByLapCorner, setGroupedByLapCorner ] = useState({})

    const [ scat_chart_1, setScat_chart_1 ] = useState()
    const [ scat_chart_2, setScat_chart_2 ] = useState()
    const [ scat_chart_3, setScat_chart_3 ] = useState()

    const [ listAns, setListAns ] = useState(0)

    const [ df_baseSetup_raw, setDf_baseSetup_raw ] = useState()
    const [ df_mapDriver, setDf_mapDriver ] = useState()
    const [ df_baseSetup, setDf_baseSetup ] = useState()
    const [ df_showSetup, setDf_showSetup ] = useState()
    const [ df_showTime, setDf_showTime ] = useState()
    const [ df_showKPI, setDf_showKPI ] = useState()
    const [ df_setupRequest, setDf_setupRequest ] = useState()
    const [ df_compareTime , setDf_compareTime ] = useState()

    const [ df_baseTime, setDf_baseTime ] = useState()
    const [ df_baseHandling, setDf_baseHandling ] = useState()
    const [ df_baseAero, setDf_baseAero ] = useState()
    const [ df_baseMech, setDf_baseMech ] = useState()

    const [ df_scatTime, setDf_scatTime ] = useState()
    const [ df_scatHandling, setDf_scatHandling ] = useState()
    const [ df_scatAero, setDf_scatAero ] = useState()
    const [ df_scatMech, setDf_scatMech ] = useState()

    const [ df_baseKPI, setDf_baseKPI ] = useState()
    const [ df_scatKPI, setDf_scatKPI ] = useState()

    const [ Obj_KPI_base, setObj_KPI_base ] = useState({})
    const [ Obj_KPI_recommend, setObj_KPI_recommend ] = useState({})
    const [ Obj_KPI_base_result, setObj_KPI_base_result ] = useState({})
    const [ Obj_KPI_recommend_result, setObj_KPI_recommend_result ] = useState({})
    // const [ Obj_KPI_actual, setObj_KPI_actual ] = useState({})

    const [ Obj_circuit_map, setObj_circuit_map ] = useState({})
    const [ Obj_circuit_sens, setObj_circuit_sens ] = useState({})
    const [ Obj_parameter_combination, setObj_parameter_combination ] = useState({})
    const [ Obj_chart_combination, setObj_chart_combination ] = useState({})

    const [ Obj_scatSetup, setObj_scatSetup ] = useState({})
    const [ Obj_baseSetup, setObj_baseSetup ] = useState({})
    // const [ Obj_tuneSetup, setObj_tuneSetup ] = useState({})

    const [ Obj_base_result, setObj_base_result ] = useState({})
    const [ Obj_scat_result, setObj_scat_result ] = useState({})

    const [ df_baseInput, setDf_baseInput ] = useState()
    const [ df_scatInput, setDf_scatInput ] = useState()

    const [ df_DOEcondition, setDf_DOEcondition ] = useState()
    const [ df_baseSetup_download, setDf_baseSetup_download ] = useState()

    const [ onnxModels, setOnnxModels ] = useState()
    const [ modelId, setModelId ] = useState()

    // const [ listAnsMinMax, setListAnsMinMax ] = useState({})
    const methodOpt = ['SetupRequest', 'bottoming', 'handling', 'aero', 'mechanical']

    const VEHICLE_ID = props.vehicleId
    const TRACK_ID = props.trackId

    const vehicleData = props.vehicleData
    const defaultAvoidBottoming = vehicleData.avoid_bottoming

    const trackData = props.trackData
    const SECTOR_NUM = trackData.sector_length

    const setup = props.setup
    const vehicleItems = props.vehicleItems
    const driverMap = props.driverMap

    useEffect(() => {
        if (!df_scatHandling) return
        compareOptimizedResults()
    }, [df_scatHandling])

    useEffect(() => {
        if (!listDriver || listDriver.length === 0) return
        if (!df_DOEcondition) return

        (async () => {
            const df_compareSetup_raw = compare_setupLapTime(df_showSetup)
            df_compareSetup_raw.setIndex({index: df_baseSetup_raw.index, inplace: true})

            const df_compareSetup = await covertNameContinuous(df_compareSetup_raw, Obj_parameter_combination, 0)
            const Obj_compareSetup = makeCompareONNXobject(df_DOEcondition, df_compareSetup)

            const tempDf_compareTime = await addCompareTimes(modelId, VEHICLE_ID, TRACK_ID, SECTOR_NUM, df_compareSetup, Obj_compareSetup, onnxModels)
            tempDf_compareTime.setIndex({index: df_compareSetup.columns, inplace: true})
            setDf_compareTime(tempDf_compareTime)
        })()
    }, [listDriver, df_DOEcondition])

    const processOptimizeConditionTables = async () => {
        const tables = document.querySelectorAll('.setup-tab-container table')
        const json = {}

        tables.forEach(async (table, tableIndex) => {
            const clonedTable = table.cloneNode(true)

            const selectInTable = clonedTable.querySelectorAll('select')
            selectInTable.forEach(select => {
                const row = select.parentElement.dataset.row
                const column = select.parentElement.dataset.column
                const originalSelect = table.querySelector(`td[data-row="${row}"][data-column="${column}"] select`)
                select.parentElement.innerText = originalSelect.value
            })

            clonedTable.querySelectorAll('tr').forEach((row, index) => {
                let setupItemId, vehicleItemMappingId
                if (index === 0) {
                    setupItemId = 'SetupItemId'
                    vehicleItemMappingId = 'VehicleItemMappingId'
                } else {
                    const td = row.querySelector('td')
                    const tdData = td.dataset
                    setupItemId = tdData.setupitemid;
                    vehicleItemMappingId = tdData.vehicleitemmappingid;
                }
            
                const setupItemIdCell = document.createElement('td');
                const vehicleItemMappingIdCell = document.createElement('td');
            
                setupItemIdCell.textContent = setupItemId;
                vehicleItemMappingIdCell.textContent = vehicleItemMappingId;
            
                row.appendChild(setupItemIdCell);
                row.appendChild(vehicleItemMappingIdCell);
            });

            const ws = utils.table_to_sheet(clonedTable, { sheet: table.dataset.sheetName })
            const toJSON = utils.sheet_to_json(ws, { header: 0, skipHeader: true })
            if (tableIndex === 0) {
                json.items = toJSON
            } else if (tableIndex === 1) {
                json.driverMap = toJSON
                //本当はUI上で変更するたびに変えたいがここでしか値を読み取ってないためここでupdateさせることにした
                props.updateDriverMap(toJSON)
            } else if (tableIndex === 2) {
                json.setupRequest = toJSON
            }
        })

        const file = await uploadJSON(json, `${Date.now()}.json`)
        const fileName = file.message.split(" ")[0]
        return [fileName, json]
    }

    const optimize = async (setupFileName, driverMap) => {
        const startOptimizedResults = await startOptimize(null, vehicleData.id, trackData.id, setupFileName, null)
        console.log("startOptimizedResults", startOptimizedResults)
        setModelId(startOptimizedResults.model.id)

        setObj_chart_combination(startOptimizedResults.Obj_chart_combination)
        setObj_parameter_combination(startOptimizedResults.Obj_parameter_combination)
        setObj_circuit_map(startOptimizedResults.Obj_circuit_map)
        setObj_circuit_sens(startOptimizedResults.Obj_circuit_sens)

        setDf_baseSetup_raw(startOptimizedResults.df_baseSetup_raw)
        setDf_baseSetup(startOptimizedResults.df_baseSetup)
        setDf_setupRequest(startOptimizedResults.df_setupRequest)

        const df_mapDriver = new dfd.DataFrame(driverMap)
        df_mapDriver.setIndex({ column: 'Parameter', drop: true, inplace: true })
        setDf_mapDriver(df_mapDriver)

        setObj_baseSetup(startOptimizedResults.optimizedResults.Obj_baseSetup)
        setDf_baseInput(startOptimizedResults.optimizedResults.df_baseInput)
        setObj_base_result(startOptimizedResults.optimizedResults.Obj_base_result)
        // setDf_baseTime(startOptimizedResults.optimizedResults.df_baseTime)

        setObj_scatSetup(startOptimizedResults.optimizedResults.Obj_scatSetup)
        setObj_scat_result(startOptimizedResults.optimizedResults.Obj_scat_result)
        setDf_scatInput(startOptimizedResults.optimizedResults.df_scatInput)
        setDf_scatTime(startOptimizedResults.optimizedResults.df_scatTime)

        const [ chart1, chart2, chart3 ] = startOptimizedResults.optimizedResults.scat_chart_list
        setScat_chart_1(chart1)
        setScat_chart_2(chart2)
        setScat_chart_3(chart3)

        setDf_DOEcondition(startOptimizedResults.df_DOEcondition)

        const labelBase = startOptimizedResults.df_baseSetup.iloc({'columns':['1:']}).columns
        const tempDf_baseInput = startOptimizedResults.optimizedResults.df_baseInput
        const tempDf_scatInput = startOptimizedResults.optimizedResults.df_scatInput
        const tempDf_scatTime = startOptimizedResults.optimizedResults.df_scatTime

        let tempDf_baseHandling = startOptimizedResults.optimizedResults.Obj_base_result[chart1]
        let tempDf_baseAero = startOptimizedResults.optimizedResults.Obj_base_result[chart2]
        let tempDf_baseMech = startOptimizedResults.optimizedResults.Obj_base_result[chart3]
        let tempDf_baseTime = startOptimizedResults.optimizedResults.df_baseTime
        let tempDf_baseKPI = startOptimizedResults.optimizedResults.df_baseKPI

        tempDf_baseHandling = dfd.concat({ dfList: [tempDf_baseInput, tempDf_baseTime, tempDf_baseHandling], axis: 1 })
        tempDf_baseAero = dfd.concat({ dfList: [tempDf_baseInput, tempDf_baseTime, tempDf_baseAero], axis: 1 })
        tempDf_baseMech = dfd.concat({ dfList: [tempDf_baseInput, tempDf_baseTime, tempDf_baseMech], axis: 1 })
        tempDf_baseKPI = dfd.concat({ dfList: [tempDf_baseKPI, tempDf_baseTime], axis: 1 })

        tempDf_baseHandling.setIndex({index: labelBase, inplace: true})
        tempDf_baseAero.setIndex({index: labelBase, inplace: true})
        tempDf_baseMech.setIndex({index: labelBase, inplace: true})
        tempDf_baseTime.setIndex({index: labelBase, inplace: true})
        tempDf_baseKPI.setIndex({index: labelBase, inplace: true})

        setDf_baseHandling(tempDf_baseHandling)
        setDf_baseAero(tempDf_baseAero)
        setDf_baseMech(tempDf_baseMech)
        setDf_baseTime(tempDf_baseTime)
        setDf_baseKPI(tempDf_baseKPI)

        let tempDf_scatHandling = startOptimizedResults.optimizedResults.Obj_scat_result[chart1]
        let tempDf_scatAero = startOptimizedResults.optimizedResults.Obj_scat_result[chart2]
        let tempDf_scatMech = startOptimizedResults.optimizedResults.Obj_scat_result[chart3]
        let tempDf_scatKPI = startOptimizedResults.optimizedResults.df_scatKPI

        tempDf_scatHandling = dfd.concat({ dfList: [tempDf_scatInput, tempDf_scatTime, tempDf_scatHandling], axis: 1 })
        tempDf_scatAero = dfd.concat({ dfList: [tempDf_scatInput, tempDf_scatTime, tempDf_scatAero], axis: 1 })
        tempDf_scatMech = dfd.concat({ dfList: [tempDf_scatInput, tempDf_scatTime, tempDf_scatMech], axis: 1 })
        tempDf_scatKPI = dfd.concat({ dfList: [tempDf_scatKPI, tempDf_scatTime], axis: 1 })

        setDf_scatHandling(tempDf_scatHandling)
        setDf_scatAero(tempDf_scatAero)
        setDf_scatMech(tempDf_scatMech)
        setDf_scatKPI(tempDf_scatKPI)

        setObj_KPI_base_result(startOptimizedResults.optimizedResults.Obj_KPI_base_result || {})
        setObj_KPI_recommend_result(startOptimizedResults.optimizedResults.Obj_KPI_recommend_result || {})

        setOnnxModels(startOptimizedResults.optimizedResults.onnxModels || [])
    }

    const optimizeHandler = async () => {
        const [fileName, optimizeCondition] = await processOptimizeConditionTables();
        optimize(fileName, optimizeCondition.driverMap)

        updateProgress(5)
    }

    const compareOptimizedResults = async () => {
        // listDriverは条件に合うように最適解を絞り込んだindexの配列
        // そのためcompare_~でlistDriverを引数として受け取り、絞り込んだ結果をlistDriverに再度格納している
        let listDriver = df_scatHandling.index
        methodOpt.forEach(m => {
            if (listDriver.length === 0) {
                alert('Could not find the answer... Please reduce your conditions and try again.')
                updateProgress(100)
            } else {
                if (m === 'SetupRequest') {
                    listDriver = compare_setupRequest(listDriver, df_baseKPI, df_scatKPI, df_setupRequest)
                } else if (m === 'bottoming') {
                    // listDriver = compare_drivermap(listDriver, Obj_circuit_map, df_baseRHFL, df_scatRHFL, df_mapDriver, 'Ride Height', defaultAvoidBottoming)
                    // listDriver = compare_drivermap(listDriver, Obj_circuit_map, df_baseRHFR, df_scatRHFR, df_mapDriver, 'Ride Height', defaultAvoidBottoming)
                    // listDriver = compare_drivermap(listDriver, Obj_circuit_map, df_baseRHRL, df_scatRHRL, df_mapDriver, 'Ride Height', defaultAvoidBottoming)
                } else if (m === 'handling') {
                    listDriver = compare_drivermap(listDriver, Obj_circuit_map, df_baseHandling, df_scatHandling, df_mapDriver, 'Handling', defaultAvoidBottoming)
                } else if (m === 'aero Balance') {
                    listDriver = compare_drivermap(listDriver, Obj_circuit_map, df_baseAero, df_scatAero, df_mapDriver, 'Aero', defaultAvoidBottoming)
                } else if (m === 'mechanical') {
                    listDriver = compare_drivermap(listDriver, Obj_circuit_map, df_baseMech, df_scatMech, df_mapDriver, 'Mechanical', defaultAvoidBottoming)
                }
            }
        })
        setListDriver(listDriver)
        console.log('listDriver', listDriver)

        const round_num = 3
        if (listDriver.length === 0) {
            setListAns(0)
            setDf_showSetup(df_baseSetup_raw.iloc({'columns':['1:']}).T)

            const tempDf_showTime = df_baseTime.iloc({"columns":["1:"]}).round(round_num).T
            tempDf_showTime.setIndex({index: df_baseTime.iloc({"columns":["1:"]}).columns, inplace: true})
            setDf_showTime(tempDf_showTime)

            setDf_showKPI(df_baseKPI.iloc({"columns":["1:"]}).round(round_num).T)
        } else {
            let [ listAns_temp ] = compare_laptime(df_scatTime.iloc({'rows':listDriver}).resetIndex(), df_baseTime)
            setListAns(listAns_temp)

            // 12/12 竹内さんコード追加
            // const listAns_RollStiffMax = compare_rollstiff(df_scatKPI.iloc({'rows':listDriver}), 'max')
            // const listAns_RollStiffMin = compare_rollstiff(df_scatKPI.iloc({'rows':listDriver}), 'min')
            // setListAnsMinMax({max: listAns_RollStiffMax, min: listAns_RollStiffMin})

            //表示用Setupテーブル作成
            let listSetup = []
            for (let i = 0; i < df_baseSetup_raw.index.length; i++){
                listSetup[i] = Obj_scatSetup[Object.keys(Obj_scatSetup)[i]].data[listAns_temp[0]]
            }

            const df_showSetup_base = makeBaseDataframe(df_baseSetup_raw)
            let tempDf_showSetup = new dfd.DataFrame({'Recommend': listSetup}, {index: df_baseSetup_raw.index})
            tempDf_showSetup = covertNameContinuous(tempDf_showSetup, Obj_parameter_combination, 1)
            tempDf_showSetup = dfd.concat({ dfList: [tempDf_showSetup, df_showSetup_base.iloc({'columns':['1:']})], axis: 1 })
            tempDf_showSetup.setIndex({index: df_baseSetup_raw.index, inplace: true})
            setDf_showSetup(tempDf_showSetup)

            let labelConstrain = df_baseSetup.iloc({'columns':[0]}).columns
            let labelBase = df_baseSetup.iloc({'columns':['1:']}).columns
            const recommendCount = labelBase.filter(l => l.includes('Recommend'))
            let counter = 0
            if (recommendCount.length > 0) {
                labelBase.forEach((l, i) => {
                    if (l.includes('Recommend')) {
                        labelBase[i] = `Recommend_${counter}`
                        counter++
                    }
                })
            }
            
            labelConstrain = labelConstrain.concat(labelBase)
            let tempDf_baseSetup_download = (new dfd.DataFrame(df_baseSetup.values, {columns: labelConstrain}))
            tempDf_baseSetup_download.setIndex({index: df_baseSetup.index, inplace: true})
            setDf_baseSetup_download(tempDf_baseSetup_download)
            df_baseTime.setIndex({index: labelBase, inplace: true})
            df_baseKPI.setIndex({index: labelBase, inplace: true})

            let tempDf_showTime = new dfd.DataFrame({'Recommend': df_scatTime.iloc({'rows':[listAns_temp[0]], 'columns':['1:']}).round(round_num).T.values}, {index: df_baseTime.iloc({'columns':['1:']}).columns})
            // let tempDf_showTimeMax = new dfd.DataFrame({'Recommend_max': df_scatTime.iloc({'rows':[listAns_RollStiffMax], 'columns':['1:']}).round(round_num).T.values}, {index: df_baseTime.iloc({'columns':['1:']}).columns})
            // let tempDf_showTimeMin = new dfd.DataFrame({'Recommend_min': df_scatTime.iloc({'rows':[listAns_RollStiffMin], 'columns':['1:']}).round(round_num).T.values}, {index: df_baseTime.iloc({'columns':['1:']}).columns})
            const df_showTimeRecommend = tempDf_showTime['Recommend']
            tempDf_showTime.drop({ columns: ['Recommend'], inplace: true })
            tempDf_showTime = dfd.concat({ dfList: [tempDf_showTime, df_baseTime.iloc({'columns':['1:']}).round(round_num).T], axis: 1 })
            tempDf_showTime = dfd.concat({ dfList: [tempDf_showTime, df_showTimeRecommend], axis: 1 })
            // tempDf_showTime = dfd.concat({ dfList: [tempDf_showTime, df_showTimeRecommend, tempDf_showTimeMax, tempDf_showTimeMin], axis: 1 })
            tempDf_showTime.setIndex({index: df_baseTime.iloc({'columns':['1:']}).columns, inplace: true})
            setDf_showTime(tempDf_showTime.T)

            let tempDf_showKPI = new dfd.DataFrame({'Recommend': df_scatKPI.iloc({'rows':[listAns_temp[0]], 'columns':['1:']}).round(round_num).T.values}, {index: df_baseKPI.iloc({'columns':['1:']}).columns})
            // let tempDf_showKPIMax = new dfd.DataFrame({'Recommend_max': df_scatKPI.iloc({'rows':[listAns_RollStiffMax], 'columns':['1:']}).round(round_num).T.values}, {index: df_baseKPI.iloc({'columns':['1:']}).columns})
            // let tempDf_showKPIMin = new dfd.DataFrame({'Recommend_min': df_scatKPI.iloc({'rows':[listAns_RollStiffMin], 'columns':['1:']}).round(round_num).T.values}, {index: df_baseKPI.iloc({'columns':['1:']}).columns})
            const df_showKPIRecommend = tempDf_showKPI['Recommend']
            tempDf_showKPI.drop({ columns: ['Recommend'], inplace: true })
            tempDf_showKPI = dfd.concat({ dfList: [tempDf_showKPI, df_baseKPI.iloc({'columns':['1:']}).round(round_num).T], axis: 1 })
            tempDf_showKPI = dfd.concat({ dfList: [tempDf_showKPI, df_showKPIRecommend], axis: 1 })
            // tempDf_showKPI = dfd.concat({ dfList: [tempDf_showKPI, df_showKPIRecommend, tempDf_showKPIMax, tempDf_showKPIMin], axis: 1 })
            tempDf_showKPI.setIndex({index: df_baseKPI.iloc({'columns':['1:']}).columns, inplace: true})
            setDf_showKPI(tempDf_showKPI.T)

            const addKPIresult = await addKPI(Obj_circuit_sens, listAns_temp, Obj_KPI_base_result, Obj_KPI_recommend_result)
            setObj_KPI_base(addKPIresult.Obj_KPI_base)
            setObj_KPI_recommend(addKPIresult.Obj_KPI_recommend)

            updateProgress(100)
        }
    }

    const resultButtonHandler = (e) => {
        const target = e.target
        const resultType = target.dataset.type

        document.querySelector('.button-result.active').classList.remove('active')
        target.classList.add('active')
        setSelectedResult(resultType)
    }

    const downloadHandler = () => {
        if (!df_showSetup) return

        downloadResult(
            VEHICLE_ID, TRACK_ID, listAns, df_showSetup, df_baseSetup_download,
            df_baseTime, df_baseHandling, df_baseAero, df_baseMech,
            df_scatTime, df_scatHandling, df_scatAero, df_scatMech,
            df_scatInput,
            scat_chart_1, scat_chart_2, scat_chart_3,
            Obj_base_result, Obj_scat_result,
            df_baseKPI, df_scatKPI, vehicleData
        )
    }

    return (
        <>
            <div className='display-flex'>
                <section className='left'>
                    <OptimizeSetupFile
                        vehicleId={ VEHICLE_ID }
                        trackId={ TRACK_ID }
                        df_showSetup={ df_showSetup }
                        setup={ setup }
                        vehicleItems={ vehicleItems }
                        driverMap={ driverMap }
                    />
                    <Track trackId= { TRACK_ID } />
                </section>
                <section className='right'>
                    <div className='display-flex button-containers'>
                            <div className='result-containers-switch-btn'>
                                <div className='button button-result active button-performance' data-type='performance' onClick={ resultButtonHandler }>{t('optimization.performanceComparison')}</div>
                                <div className='button button-result button-trend' data-type='trend' onClick={ resultButtonHandler }>{t('optimization.trendConfirmation')}</div>
                            </div>
                            <div className='result-action-btn'>
                                <div className='button action-button' onClick={ optimizeHandler }>{t('optimization.executeOptimization')}</div>
                                <div className='button button-download button-save-result' onClick={ downloadHandler }><img src={downloadBtn}/></div>
                            </div>
                    </div>

                    { selectedResult === 'performance' && 
                        <ResultPerformance
                            df_showSetup={ df_showSetup }
                            df_showTime={ df_showTime }
                            df_showKPI={ df_showKPI }
                            Obj_parameter_combination={ Obj_parameter_combination }
                            Obj_chart_combination={ Obj_chart_combination }
                            Obj_circuit_sens={ Obj_circuit_sens }
                            Obj_KPI_base={ Obj_KPI_base }
                            Obj_KPI_recommend={ Obj_KPI_recommend }
                            Obj_base_result={ Obj_base_result }
                            Obj_scat_result={ Obj_scat_result }
                            listAns={ listAns }
                            df_baseSetup={ df_baseSetup }
                            df_mapDriver={ df_mapDriver }
                            scat_chart_1={ scat_chart_1 }
                            scat_chart_2={ scat_chart_2 }
                            scat_chart_3={ scat_chart_3 }
                            df_baseHandling={ df_baseHandling }
                            df_baseAero={ df_baseAero }
                            df_baseMech={ df_baseMech }
                            df_scatAero={ df_scatAero }
                            df_scatMech={ df_scatMech }
                            df_baseTime={ df_baseTime }
                            df_baseInput={ df_baseInput }
                            df_scatTime={ df_scatTime }
                            df_scatInput={ df_scatInput }
                            // listAnsMinMax={ listAnsMinMax }
                            df_compareTime={ df_compareTime }
                        /> }
                    { selectedResult === 'trend' &&
                        <ResultTrend
                            df_baseSetup={ df_baseSetup }
                            df_mapDriver={ df_mapDriver }
                            df_baseHandling={ df_baseHandling }
                            df_baseAero={ df_baseAero }
                            df_baseMech={ df_baseMech }
                            df_baseTime={ df_baseTime }
                            df_baseInput={ df_baseInput }
                            Obj_base_result={ Obj_base_result }
                            df_scatHandling={ df_scatHandling }
                            df_scatAero={ df_scatAero }
                            df_scatMech={ df_scatMech }
                            df_scatTime={ df_scatTime }
                            df_scatInput={ df_scatInput }
                            Obj_scat_result={ Obj_scat_result }
                            Obj_chart_combination={ Obj_chart_combination }
                            Obj_circuit_sens={ Obj_circuit_sens }
                            scat_chart_1={ scat_chart_1 }
                            scat_chart_2={ scat_chart_2 }
                            scat_chart_3={ scat_chart_3 }
                            listAns={ listAns }
                            df_DOEcondition={ df_DOEcondition }
                            df_baseKPI={ df_baseKPI }
                            df_scatKPI={ df_scatKPI }
                        /> }
                    { selectedResult === 'sensitivity' && <ResultSensitivity /> }

                    
                </section>
            </div>
        </>
    )
}

export default Optimization

function uploadJSON(data, fileName) {
    return new Promise((resolve, reject) => {
        fetch('/api/v1/files/json', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ data, fileName })
        })
        .then(response => response.json())
        .then(response => {
            if (response.status === 200) {
                return resolve(response)
            }
        })
        .catch(error => {
            console.log('error', error)
            alert('error uploading json')
        })
    })
}